import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Icon, Dropdown, Menu, Container, Label, Search, Table } from "semantic-ui-react";
import { setFormatAction } from "./Actions.js";
import { cloneDeep, filter, uniqBy, set, escapeRegExp, each, get, map, debounce } from "lodash";
import { PUBLISHERS } from "./Constants";
import { push } from "react-router-redux";

class Formats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sortSize: "descending",
      publishersFilter: [],
      formatsFilter: [],
      sortName: "descending",
    };
  }

  componentDidMount() {}

  sortSize() {
    if (this.state.sortSize == "descending") {
      this.setState({ sortSize: "ascending" });
    } else {
      this.setState({ sortSize: "descending" });
    }
  }

  handleResultSelect(event, data) {
    var f = cloneDeep(this.props.format);
    set(f, "value", data.result.title);
    set(f, "_id", data.result._id);
    this.props.setFormat(f);
    this.goTo(data.result._id);
  }
  handleSearchChange = (e, { value }) => {
    var f = cloneDeep(this.props.format);
    set(f, "value", value);
    set(f, "_id", null);
    this.props.setFormat(f);
    this.setState({ loading: true });

    setTimeout(() => {
      if (get(this.props.format, "value", "").length < 1) {
        this.setState({ loading: false });
        this.props.setFormat({ value: "", _id: null, results: [] });
      }
      const re = new RegExp(escapeRegExp(get(this.props.format, "value", null)), "i");
      const isMatch = (result) => re.test(result.title);
      var formattedArray = filter(this.props.formats, isMatch);
      var array = [];
      each(formattedArray, (obj, index) => {
        if (obj.visible) {
          console.log(obj);
          array.push({
            key: index,
            title: obj.title,
            description: obj.description,
            _id: obj._id,
            visible: obj.visible.toString(),
            width: obj.width,
            height: obj.height,
            video: obj.video.toString(),
            click: obj.click.toString(),
            pubs: obj.publishers.length > 1 ? obj.publishers : "All",
            responsive: obj.responsive.toString(),
            expand: obj.expand.toString(),
          });
        }
      });
      this.setState({ loading: false });
      var f = cloneDeep(this.props.format);
      set(f, "results", array);
      this.props.setFormat(f);
    }, 100);
  };

  selectPublisher(e, data) {
    this.setState({ publishersFilter: data.value });
  }

  selectFormat(e, data) {
    this.setState({ formatsFilter: data.value });
  }
  getFormatsSizes() {
    var array = [];
    each(this.props.formats, (format, index) => {
      array.push({
        text: format.width + "x" + format.height,
        key: index,
        value: format.width + "x" + format.height,
      });
    });
    return uniqBy(array, "value");
  }
  openWindow(path) {
    console.log(path);
  }
  goTo(path) {
    this.props.history.push("./formats/" + path);
  }
  render() {
    let visibleFormats = [];
    if (get(this.props.format, "results", []).length < 1) {
      visibleFormats = filter(get(this.props, "formats", []), {
        visible: true,
      });
    } else {
      visibleFormats = filter(get(this.props.format, "results", []), {
        visible: "true",
      });
    }
    let formatSpecs = map(visibleFormats, (spec, index) => {
      return {
        name: spec.title,
        size: spec.width + "x" + spec.height,
        desktop: spec.desktop,
        mobile: spec.mobile,
        responsive: spec.responsive,
        expand: spec.expand,
        id: spec._id,
        video: spec.video,
        click: spec.click,
        description: spec.description.toString(),
        pubs: get(spec, "publishers", []).length < 1 ? "All" : get(spec, "publishers").toString(),
      };
    });

    if (this.state.formatsFilter.length > 0) {
      var array = [];
      each(this.state.formatsFilter, (size, index) => {
        each(formatSpecs, (format, index) => {
          if (format.size.indexOf(size) >= 0) {
            array.push(format);
          }
        });
      });
      formatSpecs = array;
    }
    if (this.state.publishersFilter.length > 0) {
      var array = [];
      each(formatSpecs, (format, index) => {
        var check = true;
        each(this.state.publishersFilter, (pub, index) => {
          console.log(format.pubs, pub);
          if (format.pubs.indexOf(pub) < 0) {
            check = false;
          }
        });
        if (check) {
          array.push(format);
        }
      });
      formatSpecs = array;
    }

    var i = 0;
    var a = 0;
    return (
      <Grid className="Formats" padded>
        <Grid.Row>
          <Grid.Column>
            <Menu stackable>
              <Menu.Item>
                <Search
                  loading={this.state.loading}
                  placeholder={"Search by name"}
                  onResultSelect={this.handleResultSelect.bind(this)}
                  onSearchChange={debounce(this.handleSearchChange, 500, {
                    leading: true,
                  })}
                  results={get(this.props.format, "results", [])}
                  value={this.props.format.value}
                  resultRenderer={({ title }) => <Label content={title} />}
                />
              </Menu.Item>
              <Menu.Menu position={"right"}>
                <Menu.Item>
                  <Dropdown
                    item={true}
                    floating={true}
                    multiple={true}
                    fluid={true}
                    pointing={false}
                    placeholder="Select Publishers"
                    onChange={this.selectPublisher.bind(this)}
                    scrolling
                    selection
                    disabled={false}
                    options={PUBLISHERS}
                  />
                </Menu.Item>
                <Menu.Item>
                  <Dropdown
                    floating={true}
                    item={true}
                    multiple={true}
                    pointing={false}
                    fluid={true}
                    ref={"dropdown-formats"}
                    placeholder="Select Formats"
                    onChange={this.selectFormat.bind(this)}
                    scrolling
                    selection
                    disabled={false}
                    options={this.getFormatsSizes()}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Container className="table-container-formats" fluid>
              <Table celled={false} basic={false} striped={false} selectable={true} compact={false} unstackable={true} padded className="table-formats">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Size</Table.HeaderCell>
                    <Table.HeaderCell>Desktop</Table.HeaderCell>
                    <Table.HeaderCell>Mobile</Table.HeaderCell>
                    <Table.HeaderCell>Responsive</Table.HeaderCell>
                    <Table.HeaderCell>Expand</Table.HeaderCell>
                    <Table.HeaderCell singleLine>Video Player</Table.HeaderCell>
                    <Table.HeaderCell singleLine>Click implemented</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Publishers</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {map(formatSpecs, (specs, index) => {
                    return (
                      <Table.Row className={"row-link"} onClick={this.goTo.bind(this, specs.id)} key={specs.id}>
                        {map(specs, (spec, index1) => {
                          a++;
                          if (typeof spec == "boolean") {
                            return (
                              <Table.Cell textAlign={"center"} singleLine={index1 !== "description" && index1 !== "pubs" ? true : false} key={a}>
                                <Icon color={spec ? "olive" : "red"} name={spec ? "check" : "close"} />
                              </Table.Cell>
                            );
                          } else {
                            if (index1 !== "id") {
                              return (
                                <Table.Cell singleLine={index1 !== "description" && index1 !== "pubs" ? true : false} key={a}>
                                  {spec}
                                </Table.Cell>
                              );
                            }
                          }
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    formats: state.Reducer.App.formats,
    section: state.Reducer.App.section,
    format: state.Reducer.App.format,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    goTo: (collection) => {
      console.log(collection);
      dispatch(push(collection));
    },
    setFormat: (format) => {
      dispatch(setFormatAction(format));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Formats);
