import React, { Component } from "react";
import { Dropdown, Form, Grid, Button } from "semantic-ui-react";
import { connect } from "react-redux";
class FormatPreview extends Component {
  render() {
    return (
      <Form.Field className="ad" control={"div"} label={this.props.format}>
        <iframe
          sandbox={"allow-scripts allow-same-origin allow-popups"}
          className="ad iframe-preview"
          src={"https://ams3.digitaloceanspaces.com/weborama/dco/" + this.props.client + "/" + this.props.format + ".html"}
          frameBorder={0}
          scrolling={"no"}
          height={parseInt(this.props.format.split("x")[1])}
          width={parseInt(this.props.format.split("x")[0])}></iframe>
      </Form.Field>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    feedData: state.Reducer.DCO.feedData,
    client: state.Reducer.DCO.client,
    format: ownProps.format,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FormatPreview);
