import React, { Component } from 'react';
import { connect } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { find, get, } from "lodash";
import Specs from "./Specs"
class PageFormat extends Component {

  constructor(props) {
    super(props);
  }


  render() {
    var format = find(this.props.formats, { "_id": this.props.match.params.id });
    console.log(format);
    return (
      <Grid className="Formats" padded>
        <Grid.Row className={"title"}>
          <Grid.Column>
            {(format) ? <Header size={"large"}><Header.Content>
              {get(format, "title", "")}
              <Header.Subheader>{get(format, "description", "")}</Header.Subheader>
            </Header.Content></Header> : <Header size='large'>Format not found</Header>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {(format) ? <Specs specs={format} size='large'></Specs> : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    formats: state.Reducer.App.formats,
    section: state.Reducer.App.section
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageFormat);
