import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Image, Header, Container } from "semantic-ui-react";
import { last, reject, get } from "lodash";
class Home extends Component {
  componentDidMount() {}

  render() {
    var currentNews = last(reject(this.props.news, { news_active: false }));
    return (
      <Container fluid={false}>
        <Grid className={"Home"} padded>
          <Grid.Row>
            <Grid.Column style={{ marginBottom: 30 }} mobile={16} tablet={10} computer={10}>
              <Header as="h1">Mission</Header>
              <p>
                Leveraging formats between publishers, media agencies and creative agencies to easily exchange specifications and certified formats.
                All the present formats found on this site are certified for ad-serving on WCM (Weborama Campaign Manager).
              </p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <Header as="h1">Format of the month</Header>
              <Header as={"h2"}>{get(currentNews, "news_title", "")}</Header>
              <p>{get(currentNews, "news_description", "")}</p>
              <Image height={400} src={get(currentNews, "news_media", "")} fluid={true} />
              <a className={"a-link"} target={"_blank"} href={get(currentNews, "news_url", "")}>
                Preview
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    section: state.Reducer.App.section,
    news: state.Reducer.App.news,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getFormats: function() {
    //     dispatch(getFormatsAction(true));
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
