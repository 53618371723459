import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Icon, Image, Header, Table } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { set, get, map, each } from "lodash";

class Specs extends Component {
  constructor(props) {
    super(props);
  }
  openWindow(url) {
    window.open(url, "_blank");
  }
  renderField(key, value) {
    if (value === "" || !value) {
      value = false;
    }
    switch (key) {
      case "visible":
        return null;
        break;
      case "_id":
        return null;
        break;
      case "width":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Width:"}</span>
            <span className={"value"}>{value.toString()}</span>
          </div>
        );
        break;
      case "height":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Height:"}</span>
            <span className={"value"}>{value.toString()}</span>
          </div>
        );
        break;
      case "rich":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Rich Media:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "expand":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Format expands:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "video":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Format has video player:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "video_progress_metrics":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Video metrics implemented:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "editable":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Template is editable:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "viewability":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Visibility implemented:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "responsive":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Format is Responsive:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "description":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Description:"}</span>
            <span className={"value"}>{value.toString()}</span>
          </div>
        );
        break;
      case "platforms":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Creative Platforms:"}</span>
            <span className={"value"}>{value.toString()}</span>
          </div>
        );
        break;
      case "publishers":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Publishers:"}</span>
            <span className={"value"}>{value.length > 0 ? value.toString() : "All"}</span>
          </div>
        );
        break;
      case "notes":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Notes:"}</span>
            <span className={"value"}>{value.toString()}</span>
          </div>
        );
        break;
      case "click":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Clickthrough is implemented:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "dco_available":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Dynamic Creative Optimization support:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "dsp_compliant":
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{"Programmatic enabled:"}</span>
            <span className={"value"}>
              <Icon color={value ? "olive" : "red"} name={value ? "check" : "close"} />
            </span>
          </div>
        );
        break;
      case "preview_url":
        return (
          <div className={"spec"} key={key}>
            <a className={"a-link"} onClick={this.openWindow.bind(this, value.toString())}>
              Preview URL
            </a>
          </div>
        );
        break;
      case "zip_html5":
        if (value) {
          return (
            <div className={"spec"} key={key}>
              <span className={"title"}>{"HTML5 Template:"}</span>
              <span className={"value"}>
                <a href={value.toString()} target={"_self"}>
                  Download
                </a>
              </span>
            </div>
          );
        } else {
          return null;
        }
      case "zip_edge":
        if (value) {
          return (
            <div className={"spec"} key={key}>
              <span className={"title"}>{"Adobe Edge Template:"}</span>
              <span className={"value"}>
                <a href={value.toString()} target={"_self"}>
                  Download
                </a>
              </span>
            </div>
          );
        } else {
          return null;
        }
      case "zip_animate":
        if (value) {
          return (
            <div className={"spec"} key={key}>
              <span className={"title"}>{"Adobe Animate Template:"}</span>
              <span className={"value"}>
                <a href={value.toString()} target={"_self"}>
                  Download
                </a>
              </span>
            </div>
          );
        } else {
          return null;
        }
      case "zip_gwd":
        if (value) {
          return (
            <div className={"spec"} key={key}>
              <span className={"title"}>{"Google Web-Design Template:"}</span>
              <span className={"value"}>
                <a href={value.toString()} target={"_self"}>
                  Download
                </a>
              </span>
            </div>
          );
        } else {
          return null;
        }
      case "pdf":
        if (value) {
          return (
            <div className={"spec"} key={key}>
              <span className={"title"}>{"Specifications PDF:"}</span>
              <span className={"value"}>
                <a href={value.toString()} target={"_self"}>
                  Download
                </a>
              </span>
            </div>
          );
        } else {
          return null;
        }
        break;
      case "title":
        return null;
        break;
      default:
        return (
          <div className={"spec"} key={key}>
            <span className={"title"}>{key}</span>
            <span className={"value"}>{value.toString()}</span>
          </div>
        );
    }
  }

  render() {
    var formatSpecs = ["width", "height", "desktop", "mobile", "responsive"];
    var featuresSpecs = ["expand", "viewability", "video", "video_progress_metrics"];
    var inventorySpecs = ["publishers", "dco_available", "dsp_compliant"];
    var filesSpecs = ["editable", "preview_url", "pdf", "zip_html5", "zip_edge", "zip_gwd", "zip_animate"];
    var obj = {};
    each(formatSpecs, (value, index) => {
      set(obj, value, get(this.props.specs, value, null));
    });
    console.log(obj);
    return (
      <Grid className="Specs">
        <Grid.Row className={"title"}>
          <Grid.Column>
            <Header>Dimensions & Devices</Header>
            <Table celled={false} basic={false} striped={false} selectable={false} compact={true} stackable={false} padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine={true}>Width</Table.HeaderCell>
                  <Table.HeaderCell singleLine={true}>Height</Table.HeaderCell>
                  <Table.HeaderCell singleLine={true}>Desktop</Table.HeaderCell>
                  <Table.HeaderCell singleLine={true}>Mobile</Table.HeaderCell>
                  <Table.HeaderCell singleLine={true}>Responsive</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  {map(formatSpecs, (value, index) => {
                    if (typeof get(this.props.specs, value) == "boolean") {
                      return (
                        <Table.Cell singleLine={true} key={index}>
                          {<Icon color={get(this.props.specs, value) ? "olive" : "red"} name={get(this.props.specs, value) ? "check" : "close"} />}
                        </Table.Cell>
                      );
                    } else {
                      return (
                        <Table.Cell singleLine={true} key={index}>
                          {get(this.props.specs, value)}
                        </Table.Cell>
                      );
                    }
                  })}
                </Table.Row>
              </Table.Body>
            </Table>
            <Header>Features</Header>
            <Table celled={false} basic={false} striped={false} selectable={false} compact={true} stackable={false} padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine={false}>Expands</Table.HeaderCell>
                  <Table.HeaderCell singleLine={false}>Viewability </Table.HeaderCell>
                  <Table.HeaderCell singleLine={false}>Video Player</Table.HeaderCell>
                  <Table.HeaderCell singleLine={false}>Video Metrics </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  {map(featuresSpecs, (value, index) => {
                    return (
                      <Table.Cell singleLine={true} key={index}>
                        {<Icon color={get(this.props.specs, value) ? "olive" : "red"} name={get(this.props.specs, value) ? "check" : "close"} />}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              </Table.Body>
            </Table>
            <Header>Media Implementation</Header>
            <Table structured celled={false} basic={false} striped={false} selectable={false} compact={true} stackable={false} padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine={false}>Certified Publishers</Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    DCO ready <span className={"tiny-text"}>Dynamic Creative Optimization</span>
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    Programmatic <span className={"tiny-text"}> DV360, AppNexus, MediaMath, GDN, Weborama SSP</span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  {map(inventorySpecs, (value, index) => {
                    console.log(get(this.props.specs, value));

                    if (typeof get(this.props.specs, value) == "boolean") {
                      return (
                        <Table.Cell singleLine={true} key={index}>
                          {<Icon color={get(this.props.specs, value) ? "olive" : "red"} name={get(this.props.specs, value) ? "check" : "close"} />}
                        </Table.Cell>
                      );
                    } else {
                      return (
                        <Table.Cell singleLine={true} key={index}>
                          {get(this.props.specs, value).length < 1 ? "All" : get(this.props.specs, value).toString()}
                        </Table.Cell>
                      );
                    }
                  })}
                </Table.Row>
              </Table.Body>
            </Table>
            <Header>Creative Templates</Header>
            <Table structured celled={false} basic={false} striped={false} selectable={false} compact={true} stackable={false} padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine={true}>Editable template</Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Icon name={"find"} /> Preview URL
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Icon name={"file pdf"} />
                    PDF Especifications
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Icon name={"html5"} /> Template HTML5
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Image inline={true} src={"./../adobe.png"} width={15} height={20} /> Template Adobe Edge
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Icon name={"google"} /> Template GWD
                  </Table.HeaderCell>
                  <Table.HeaderCell singleLine>
                    <Image inline={true} src={"./../adobe.png"} width={15} height={20} /> Template Adobe Animate
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  {map(filesSpecs, (value, index) => {
                    console.log(value);
                    if (typeof get(this.props.specs, value) == "boolean") {
                      return (
                        <Table.Cell singleLine={true} key={index}>
                          {<Icon color={get(this.props.specs, value) ? "olive" : "red"} name={get(this.props.specs, value) ? "check" : "close"} />}
                        </Table.Cell>
                      );
                    } else if (value !== "preview_url") {
                      return (
                        <Table.Cell singleLine={true} key={index}>
                          {get(this.props.specs, value).length > 1 ? (
                            <a className={"a-link"} target={"_blank"} href={get(this.props.specs, value, "")}>
                              Download
                            </a>
                          ) : (
                            "Not available"
                          )}
                        </Table.Cell>
                      );
                    } else {
                      return (
                        <Table.Cell singleLine={true} key={index}>
                          {get(this.props.specs, value).length > 1 ? (
                            <a className={"a-link"} target={"_blank"} href={get(this.props.specs, value, "")}>
                              Preview
                            </a>
                          ) : (
                            "Not available"
                          )}
                        </Table.Cell>
                      );
                    }
                  })}
                </Table.Row>
              </Table.Body>
            </Table>
            <Table structured celled={false} basic={false} striped={false} selectable={false} compact={true} stackable={false} padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine={true}>Notes</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell singleLine={true} key={0}>
                    {get(this.props.specs, "notes", "")}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    specs: ownProps.specs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getFormats: function() {
    //     dispatch(getFormatsAction(true));
    // },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Specs));
