import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "semantic-ui-react";
import { Route, Switch, withRouter } from "react-router-dom";
import { request, getFormatsAction, toggleEditorMenuAction, setSectionAction, getNewsFormatsAction } from "./Actions.js";
import MenuContainer from "./MenuContainer.jsx";
import Formats from "./Formats.jsx";
import TagPreviewer from "./TagPreviewer.jsx";
import VASTPreviewer from "./VASTPreviewer.jsx";
import Home from "./Home.jsx";
import PageFormat from "./PageFormat.jsx";
import BoardTag from "./BoardTag.jsx";
import DCOTester from "./DCOTester.jsx";
import FeedCreator from "./FeedCreator.jsx";
import RichMedia from "./RichMedia.jsx";
import IPFinder from "./IPFinder.jsx";
import NotFound from "./NotFound.jsx";
import AdminEditor from "./AdminEditor.jsx";
import { get, find } from "lodash";
import { GET_FORMATS, GET_NEWS, SECTIONS, VERSION } from "./Constants";
import DCOInputs from "./DCOInputs.jsx";
class App extends Component {
  componentDidMount() {
    console.log(VERSION);
    this.props.getFormats();
    this.props.loadNewsDocuments();
  }
  toggleEditor() {
    this.props.toggleEditorMenu(!this.props.editor);
  }
  render() {
    return (
      <Grid>
        {window.location.href.indexOf("admin=true") >= 0 ? (
          <Button className={"editor-menu"} onClick={this.toggleEditor.bind(this)}>
            Toggle Editor
          </Button>
        ) : null}
        {this.props.editor ? <AdminEditor /> : null}
        <Grid.Row className={"red-top"}>
          <Grid.Column>
            <MenuContainer />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div id={"Header"}>
              <div className={"header-section"}>{get(find(SECTIONS, { path: this.props.location.pathname.replace("/", "") }), "name", "")}</div>
            </div>
            <Switch>
              {/* <Projects saveScene={this.save.bind(this)} pauseVideoSphere={this.pauseVideoSphere.bind(this)} preloadAssets={this.preloadAssets.bind(this)} projects={this.props.projects}/> */}
              <Route exact={true} path={"/"} component={Home} />
              <Route exact={true} path={"/preroll-previewer"} component={VASTPreviewer} />
              <Route exact={true} path={"/display-previewer"} component={TagPreviewer} />
              <Route exact={true} path={"/ipfinder"} component={IPFinder} />
              <Route exact={true} path={"/formats"} component={Formats} />
              <Route exact={true} path={"/dco"} component={DCOInputs} />
              <Route exact={true} path={"/dco/:client/:feed_id"} component={DCOTester} />
              <Route exact={true} path={"/collect"} component={BoardTag} />
              <Route exact={true} path={"/feed"} component={FeedCreator} />
              <Route exact={true} path={"/richmedia/:id?"} component={RichMedia} />
              <Route exact={true} path={"/formats/:id"} component={PageFormat} />
              <Route exact path={"*"} component={NotFound} />
            </Switch>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    section: state.Reducer.App.section,
    editor: state.Reducer.App.editor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSection: (section) => {
      dispatch(setSectionAction(section));
    },
    getFormats: () => {
      dispatch(
        request(GET_FORMATS.url, getFormatsAction, {
          method: GET_FORMATS.method,
        }),
      );
    },
    loadNewsDocuments: () => {
      dispatch(
        request(GET_NEWS.url, getNewsFormatsAction, {
          method: GET_NEWS.method,
        }),
      );
    },
    toggleEditorMenu(show) {
      dispatch(toggleEditorMenuAction(show));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
