import React, { Component } from 'react';
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

class VASTPreviewer extends Component {
    componentDidMount() {

    }


    render() {
        return (
            <Grid className="VASTPreviewer" padded>
                <Grid.Row>
                    <Grid.Column>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        section: state.Reducer.App.section
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        // getFormats: function() {
        //     dispatch(getFormatsAction(true));
        // },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VASTPreviewer);
