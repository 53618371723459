import React, { Component } from "react";
import { connect } from "react-redux";
import { isNil } from "lodash";
import { Form, Grid, Button, Input } from "semantic-ui-react";
import { setClientNameAction, setFeedIDAction, setIDOfRulesAction, setFeedUUIDAction } from "./Actions.js";
class DCOInputs extends Component {
  loadInfo(e, data) {
    this.props.history.push("/dco/" + this.props.client + "/" + this.props.feedID + "?rule=" + this.props.rulesID);
  }
  setFeedID(e, data) {
    this.props.setFeedID(data.value);
    this.props.setFeedUUID(data.value);
  }
  setClient(e, data) {
    this.props.setClientName(data.value);
  }
  setIDOfRules(e, data) {
    this.props.setIDOfRules(data.value);
  }

  render() {
    return (
      <Grid className="DCOTester" padded>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  width={2}
                  label={"Client Label"}
                  value={isNil(this.props.client) ? "" : this.props.client}
                  onChange={this.setClient.bind(this)}
                />
                <Form.Field control={Input} width={4} label={"Feed ID"} value={this.props.feedID} onChange={this.setFeedID.bind(this)} />
                <Form.Field
                  control={Input}
                  width={2}
                  value={this.props.rulesID}
                  label={"Rules (WAM ID)"}
                  type={"number"}
                  onChange={this.setIDOfRules.bind(this)}
                />
                <Form.Field
                  control={Button}
                  width={2}
                  disabled={!isNil(this.props.client) && this.props.feedID.length > 1 ? false : true}
                  label={"Load"}
                  color={"olive"}
                  icon={"arrow right"}
                  onClick={this.loadInfo.bind(this)}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    rulesID: state.Reducer.DCO.rulesID,
    feedID: state.Reducer.DCO.feedID,
    client: state.Reducer.DCO.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFeedUUID: (uuid) => {
      dispatch(setFeedUUIDAction(uuid));
    },
    setClientName: (name) => {
      dispatch(setClientNameAction(name));
    },
    setFeedID: (id) => {
      dispatch(setFeedIDAction(id));
    },
    setIDOfRules: (id) => {
      dispatch(setIDOfRulesAction(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DCOInputs);
