import _ from "lodash";

export function toggleLoaderAction(show) {
  return {
    type: "TOGGLE_LOADER",
    show,
  };
}

export function serverMessageAction(response) {
  return {
    type: "SERVER_MESSAGE",
    response,
  };
}
export function setHTMLAction(html) {
  return {
    type: "SET_HTML_TAG",
    html,
  };
}
export function getFeedAction(feed) {
  return {
    type: "GET_FEED",
    feed,
  };
}

export function progressLoaderAction(value) {
  return {
    type: "LOADER_PROGRESS",
    value,
  };
}

export function getFormatsAction(response) {
  return {
    type: "GET_FORMATS",
    response,
  };
}
export function createFormatsAction(response) {
  return {
    type: "CREATE_FORMATS",
    response,
  };
}
export function deleteFormatsAction(response) {
  return {
    type: "DELETE_FORMATS",
    response,
  };
}
export function saveFormatsAction(response) {
  return {
    type: "SAVE_FORMATS",
    response,
  };
}
export function setDataFeedAction(response) {
  return {
    type: "SET_FEED",
    response,
  };
}
export function setRichMediaDataAction(response) {
  return {
    type: "SET_DATA_RICHMEDIA",
    response,
  };
}
export function setRulesDataAction(rules) {
  return {
    type: "SET_RULES_DATA",
    rules,
  };
}
export function loadFlushIframeAction(show) {
  return {
    type: "LOAD_FLUSH_IFRAME",
    show,
  };
}
export function setRulesStatusAction(status) {
  return {
    type: "SET_RULES_STATUS",
    status,
  };
}
export function setSelectedRuleAction(rule) {
  return {
    type: "SET_SELECTED_RULE",
    rule,
  };
}
export function setIDOfRulesAction(id) {
  return {
    type: "SET_RULES_ID",
    id,
  };
}
export function setFeedUUIDAction(uuid) {
  return {
    type: "SET_FEED_UUID",
    uuid,
  };
}
export function setRichMediaUUIDAction(uuid) {
  return {
    type: "SET_RICHMEDIA_UUID",
    uuid,
  };
}

export function setLoadedRichMediaUUIDAction(uuid) {
  return {
    type: "SET_LOADED_RICHMEDIA_UUID",
    uuid,
  };
}
export function setRichMediaFormatAction(format) {
  return {
    type: "SET_RICHMEDIA_FORMAT",
    format,
  };
}
export function setClientNameAction(name) {
  return {
    type: "SET_CLIENT_NAME",
    name,
  };
}
export function setRetargetingValueAction(retargeting_value) {
  return {
    type: "SET_RETARGETING_VALUE",
    retargeting_value,
  };
}
export function setFeedIDAction(id) {
  return {
    type: "SET_FEED_ID",
    id,
  };
}
export function setRichMediaIDAction(id) {
  return {
    type: "SET_RICHMEDIA_ID",
    id,
  };
}
export function setFormatsAction(formats) {
  return {
    type: "SET_FORMATS",
    formats,
  };
}
export function setFeedStatusAction(status) {
  return {
    type: "SET_FEED_STATUS",
    status,
  };
}
export function setFeedDataAction(feedData) {
  return {
    type: "SET_FEED_DATA",
    feedData,
  };
}
export function getNewsFormatsAction(response) {
  return {
    type: "GET_NEWS",
    response,
  };
}
export function setFeedNameAction(name) {
  return {
    type: "SET_FEED_NAME",
    name,
  };
}
export function addBoardRuleAction(rules) {
  return {
    type: "ADD_RULE",
    rules,
  };
}
export function deleteBoardRuleAction(rules) {
  return {
    type: "DELETE_RULE",
    rules,
  };
}
export function saveBoardRulesAction(rules) {
  return {
    type: "SAVE_RULES",
    rules,
  };
}
export function setBoardRulesAction(rules) {
  return {
    type: "SET_RULES",
    rules,
  };
}
export function setWCMIDAction(id) {
  return {
    type: "SET_WCM_ID",
    id,
  };
}
export function setWAMIDAction(id) {
  return {
    type: "SET_WAM_ID",
    id,
  };
}
export function toggleSplitToolsAction(show) {
  return {
    type: "TOGGLE_SPLIT_TOOLS",
    show,
  };
}
export function setLabelAction(label) {
  return {
    type: "SET_LABEL",
    label,
  };
}
export function toggleModalAction(show, data) {
  return {
    type: "TOGGLE_FEED_MODAL",
    show,
    data,
  };
}
export function toggleRichMediaModalAction(show, data) {
  return {
    type: "TOGGLE_RICHMEDIA_MODAL",
    show,
    data,
  };
}
export function createNewsFormatsAction(response) {
  return {
    type: "CREATE_NEWS",
    response,
  };
}
export function deleteNewsFormatsAction(response) {
  return {
    type: "DELETE_NEWS",
    response,
  };
}
export function saveNewsFormatsAction(response) {
  return {
    type: "SAVE_NEWS",
    response,
  };
}
export function setFormatAction(format) {
  return {
    type: "SET_FORMAT",
    format,
  };
}
export function errorAction(message) {
  return {
    type: "ERROR",
    message,
  };
}

export function setSectionAction(section) {
  return {
    type: "SET_SECTION",
    section,
  };
}

export function userStatusAction(response) {
  return {
    type: "USER_STATUS",
    response,
  };
}

export function toggleEditorMenuAction(show) {
  return {
    type: "TOGGLE_EDITOR_MENU",
    show,
  };
}

export function request(url, actions, opts, callback) {
  console.log(url);
  return function (dispatch) {
    var data = null;
    var xhr = new XMLHttpRequest();

    xhr.addEventListener("load", function (e) {
      //console.log("COMPLETE REQUEST");
    });
    xhr.addEventListener("error", function (e) {
      switch (xhr.status) {
        case 0:
          dispatch(errorAction());
          dispatch(
            serverMessageAction({
              message: "Offline - Cannot detect server.",
              show: true,
              uuid: Math.round(Math.random() * 10000000000000),
              logged: false,
            })
          );
          break;
        default:
          console.log("ERROR", "STATUS", xhr.status);
      }
    });
    xhr.addEventListener("abort", function (e) {
      dispatch(errorAction());
      dispatch(
        serverMessageAction({
          message: "Aborted connection.",
          show: true,
          uuid: Math.round(Math.random() * 10000000000000),
        })
      );
    });
    xhr.addEventListener("readystatechange", function (e) {
      if (this.readyState === 4) {
        if (this.responseText && this.responseText.length > 0) {
          try {
            var response = JSON.parse(this.responseText);
            console.log(response);
            if (actions) {
              dispatch(actions(response.result));
            }
            if (callback !== undefined && callback !== null) {
              if (typeof callback === "function") {
                if (!response.result) {
                  callback(response);
                } else {
                  callback(response.result);
                }
              }
            }
            if (response.message !== undefined) {
              var obj = {
                message: response.message,
                show: response.show,
                uuid: Math.round(Math.random() * 10000000000000),
                color: response.color,
              };
              if (response.logged !== undefined) {
                console.log(response);
                obj.logged = response.logged;
              }
              dispatch(serverMessageAction(obj));
            }
            //dispatch(userStatusAction(response))
          } catch (e) {
            console.error(e);
          }
        }
        dispatch(progressLoaderAction(100));
        dispatch(toggleLoaderAction(false));
      }
    });
    xhr.open(opts.method, url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Cache-Control", "no-cache");
    var x = _.cloneDeep(opts.bodyData);
    data = JSON.stringify(x);
    dispatch(toggleLoaderAction(true));
    xhr.send(data);
  };
}

export function upload(url, actions, opts, callback) {
  return function (dispatch) {
    var data = null;
    var xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("loadend", function (e) { });
    xhr.upload.addEventListener("progress", function (e) {
      console.log(e);
      var percentage = Math.round((e.loaded * 100) / e.total);
      dispatch(progressLoaderAction(percentage));
    });
    xhr.upload.addEventListener("error", function (e) {
      switch (xhr.status) {
        case 0:
          dispatch(errorAction());
          dispatch(
            serverMessageAction({
              message: "Offline - Cannot detect server.",
              show: true,
              logged: false,
              color: "red",
              uuid: Math.round(Math.random() * 10000000000000),
            })
          );
          break;
        default:
          console.log("ERROR", "STATUS", xhr.status);
      }
    });
    xhr.upload.addEventListener("abort", function (e) {
      dispatch(errorAction());
      dispatch(
        serverMessageAction({
          message: "Aborted connection.",
          show: true,
          color: "yellow",
          uuid: Math.round(Math.random() * 10000000000000),
        })
      );
    });
    xhr.addEventListener("readystatechange", function (e) {
      if (this.readyState === 4) {
        if (this.responseText && this.responseText.length > 0) {
          try {
            var response = JSON.parse(this.responseText);
            console.info("REQUEST URL:", url);
            console.info("REQUEST RESULT:", response);
            if (response.logged) {
              if (actions) {
                dispatch(actions(response.result));
              }
              if (callback !== undefined && callback !== null) {
                if (typeof callback === "function") {
                  console.log("CALLBACK", callback);
                  callback(response.result);
                }
              }
            } else {
              console.error("NOT LOGGED");
            }
            if (response.message !== undefined) {
              dispatch(
                serverMessageAction({
                  message: response.message,
                  show: response.show,
                  color: response.color,
                  uuid: Math.round(Math.random() * 10000000000000),
                })
              );
            }
            dispatch(userStatusAction(response));
          } catch (e) {
            console.error(e);
          }
        }
        dispatch(progressLoaderAction(100));
        dispatch(toggleLoaderAction(false));
      }
    });
    xhr.open(opts.method, url);
    if (!opts.files) {
      alert("No files on request.");
    }
    data = opts.files;

    dispatch(toggleLoaderAction(true));
    xhr.send(data);
  };
}
