import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, TextArea, Button } from "semantic-ui-react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { request, getFormatsAction } from "./Actions.js";
import { GET_IPS } from "./Constants.js";
import _ from "lodash";
import Specs from "./Specs.jsx";

var index = 0;
class IPFinder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: "",
      cp: "",
      division: "",
      ips: [],
    };
  }
  checkIP(ip) {
    console.log(ip);
    if (ip.length > 3) {
      this.props.getIP(ip, (response) => {
        console.log(response);
        index++;
        var currentCities = _.cloneDeep(this.state.city);
        var currentCPs = _.cloneDeep(this.state.cp);
        var currentDivs = _.cloneDeep(this.state.division);
        console.log(_.get(response, "city.names.en", "?"));
        currentCities += _.get(response, "city.names.en", "?") + "\n";
        currentCPs += _.get(response, "postal.code", "?") + "\n";
        currentDivs += _.get(response, "subdivisions[0].names.en", "?") + "\n";
        this.setState({
          city: currentCities,
          cp: currentCPs,
          division: currentDivs,
        });
        if (index >= this.state.ips.length) {
          alert("finished");
        } else {
          this.checkIP(this.state.ips[index]);
          console.log("LOG:", this.state.ips[index]);
        }
      });
    } else {
      console.log("error");
    }
  }
  handleChange(e, data) {
    this.setState({
      ips: data.value.split("\n"),
      city: "",
      cp: "",
      division: "",
    });
    index = 0;
  }
  clean(e, data) {
    document.querySelector("#ips").value = null;
    index = 0;
    this.setState({ ips: "", city: "", cp: "", division: "" });
  }

  startCheck() {
    this.checkIP(this.state.ips[0]);
  }
  render() {
    return (
      <Grid className="Formats" padded>
        <Grid.Row className={"title"}>
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <TextArea id={"ips"} onChange={this.handleChange.bind(this)} />
          </Grid.Column>
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <TextArea value={this.state.city} />
          </Grid.Column>
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <TextArea value={this.state.cp} />
          </Grid.Column>
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <TextArea value={this.state.division} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button onClick={this.startCheck.bind(this)}>Start Check</Button>
            <Button onClick={this.clean.bind(this)}>Clean</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {this.state.ips.length}:{index}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    section: state.Reducer.App.section,
    IPOutput: state.Reducer.App.IPOutput,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIP: (ip, callback) => {
      dispatch(
        request(
          GET_IPS.url + ip,
          null,
          {
            method: GET_IPS.method,
          },
          callback
        )
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IPFinder)
);
