import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import "./css/App.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";
import Reducer from "./Reducers";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import "semantic-ui-css/semantic.min.css";
import { Provider, ReactReduxContext } from "react-redux";
import { composeWithDevTools } from "remote-redux-devtools";

const composeEnhancers = composeWithDevTools({
  hostname: "localhost",
  name: "Weborama - Design",
  port: "8123",
  suppressConnectErrors: false,
});
let store = null;
console.log("NODE_ENV", process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {
  store = createStore(
    combineReducers({
      Reducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
} else {
  store = createStore(
    combineReducers({
      Reducer,
    }),
    applyMiddleware(thunk)
  );
}

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
