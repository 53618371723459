import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Icon, Dropdown, Menu, Form, Button, Input, Checkbox } from "semantic-ui-react";
import {
  request,
  getFormatsAction,
  createFormatsAction,
  saveFormatsAction,
  deleteFormatsAction,
  getNewsFormatsAction,
  createNewsFormatsAction,
  saveNewsFormatsAction,
  deleteNewsFormatsAction,
  toggleEditorMenuAction,
} from "./Actions.js";
import { get, set, find, cloneDeep, each, unset } from "lodash";
import moment from "moment";
import { GET_FORMATS, DELETE_FORMATS, SAVE_FORMATS, CREATE_FORMATS, GET_NEWS, DELETE_NEWS, SAVE_NEWS, CREATE_NEWS, PUBLISHERS } from "./Constants";

let canSave = false;
const DATA_STRUCTURE = {
  width: 300,
  height: 250,
  desktop: false,
  mobile: false,
  responsive: false,
  title: "New Format",
  description: "",
  preview_url: "",
  zip_html5: "",
  zip_edge: "",
  zip_animate: "",
  zip_gwd: "",
  click: true,
  pdf: "",
  expand: false,
  video: false,
  video_progress_metrics: false,
  viewability: "",
  platforms: [],
  editable: false,
  dco_available: false,
  dsp_compliant: false,
  visible: true,
  notes: "",
  publishers: [],
};

const NEWS_STRUCTURE = {
  news_description: "",
  news_title: "Select News",
  news_media: "",
  news_active: false,
  news_date: null,
  news_url: "",
};

class AdminEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insertloading: false,
      editor: "news",
      deleteloading: false,
      refreshloading: false,
      currentNewsSelectedID: null,
      selectedNews: null,
      selectedFormat: null,
      id: null,
      news_date: null,
      news_description: "",
      news_title: "Select News",
      news_media: "",
      news_url: "",
      news_active: false,
      saved: true,
      mobile: false,
      click: true,
      loading: false,
      currentSelectedID: null,
      width: 300,
      height: 250,
      desktop: false,
      responsive: false,
      title: "Select Format",
      description: "",
      preview_url: "",
      zip_html5: "",
      zip_edge: "",
      zip_animate: "",
      zip_gwd: "",
      pdf: "",
      expand: false,
      video: false,
      video_progress_metrics: false,
      viewability: "",
      platforms: [],
      editable: false,
      dco_available: false,
      dsp_compliant: false,
      visible: true,
      notes: "",
      publishers: [],
      resetdata: DATA_STRUCTURE,
    };
  }
  handleEditorType() {
    if (this.state.editor === "formats") {
      this.setState({ editor: "news" });
    } else {
      this.setState({ editor: "formats" });
    }
  }
  componentDidMount() {
    // document.onkeydown = (event)=>{
    //     event.preventDefault();
    //     if(event.key === "Control"){
    //       ctrl = true;
    //     }
    //     if(event.key.toLowerCase() === "s"){
    //       s = true;
    //     }
    //     if(event.key.toLowerCase() === "d"){
    //       d = true;
    //     }
    //     if(ctrl && s && canSave) {
    //       this.saveDocument();
    //     }
    //     if(ctrl && d && this.props.currentSelectedID !== null) {
    //       this.deleteDocument();
    //     }
    // }
    // document.onkeyup = (event)=>{
    //     event.preventDefault();
    //     if(event.key === "Control"){
    //       ctrl = false;
    //     }
    //     if(event.key.toLowerCase() === "s"){
    //       s = false;
    //     }
    //     if(event.key.toLowerCase() === "d"){
    //       d = true;
    //     }
    // }
  }

  componentWillUnmount() {
    document.onkeydown = null;
    document.onkeyup = null;
  }

  insertNewsFormat() {
    this.setState({ insertloading: true });
    console.log("1");
    this.resetData();
    var data = cloneDeep(NEWS_STRUCTURE);
    set(data, "news_title", "New " + moment().format("YYYY-MM-DD HH:mm:ss"));
    set(data, "news_date", moment().format("YYYY-MM-DD"));
    this.props.createNewsDocument(data, (res) => {
      this.loadNewsFormats((response) => {
        console.log(response);
        this.setState({ currentNewsSelectedID: res.insertedId, selectedNews: null, news_title: data.news_title, insertloading: false });
      });
    });
  }
  insertFormat() {
    this.setState({ insertloading: true });
    console.log("1");
    this.resetData();
    this.props.createDocument(DATA_STRUCTURE, (res) => {
      this.loadNewsFormats((response) => {
        console.log(response);
        this.setState({ currentSelectedID: res.insertedId, selectedFormat: null, title: "New Format", insertloading: false });
      });
    });
  }
  duplicateFormat() {
    this.setState({ insertloading: true });
    var data = cloneDeep(this.state);

    unset(data, "id");
    unset(data, "currentSelectedID");
    unset(data, "loading");
    unset(data, "currentNewsSelectedID");
    unset(data, "news_title");
    unset(data, "editor");
    unset(data, "resetdata");
    unset(data, "insertloading");
    unset(data, "refreshloading");
    unset(data, "deleteloading");
    unset(data, "selectedNews");
    unset(data, "selectedFormat");

    unset(data, "news_date");
    unset(data, "news_active");
    unset(data, "news_description");
    unset(data, "news_media");
    unset(data, "news_title");
    unset(data, "news_url");

    unset(data, "saved");
    this.props.createDocument(data, (res) => {
      this.loadFormats((response) => {
        this.setState({ currentSelectedID: res.insertedId, selectedFormat: null, title: data.title, insertloading: false });
      });
    });
  }
  deleteDocument() {
    if (this.state.currentSelectedID) {
      this.setState({ deleteloading: true });
      this.props.deleteDocument(this.state.currentSelectedID, (res) => {
        this.loadFormats();
        this.setState({ deleteloading: false, title: "Select Format" });
        this.resetData();
      });
    }
  }
  deleteNewsDocument() {
    if (this.state.currentNewsSelectedID) {
      this.setState({ deleteloading: true });
      this.props.deleteNewsDocument(this.state.currentNewsSelectedID, (res) => {
        this.loadNewsFormats();
        this.setState({ deleteloading: false, news_title: "Select News" });
        this.resetNewsData();
      });
    }
  }

  loadFormats(cb) {
    this.setState({ refreshloading: true });
    this.props.loadDocuments((res) => {
      if (cb) {
        cb(res);
      }
      this.setState({ refreshloading: false });
    });
    this.formatDataFormats();
  }

  loadNewsFormats(cb) {
    this.setState({ refreshloading: true });
    this.props.loadNewsDocuments((res) => {
      if (cb) {
        cb(res);
      }
      this.setState({ refreshloading: false });
    });
    this.formatDataNews();
  }

  resetData() {
    this.setState({
      currentSelectedID: null,
      width: get(DATA_STRUCTURE, "width", 300),
      height: get(DATA_STRUCTURE, "height", 250),
      desktop: get(DATA_STRUCTURE, "desktop"),
      responsive: get(DATA_STRUCTURE, "responsive"),
      title: "",
      click: true,
      mobile: get(DATA_STRUCTURE, "mobile"),
      description: get(DATA_STRUCTURE, "description"),
      zip_html5: get(DATA_STRUCTURE, "zip_html5"),
      zip_edge: get(DATA_STRUCTURE, "zip_edge"),
      zip_animate: get(DATA_STRUCTURE, "zip_animate"),
      zip_gwd: get(DATA_STRUCTURE, "zip_gwd"),
      pdf: get(DATA_STRUCTURE, "pdf"),
      expand: get(DATA_STRUCTURE, "expand"),
      video: get(DATA_STRUCTURE, "video"),
      video_progress_metrics: get(DATA_STRUCTURE, "video_progress_metrics"),
      viewability: get(DATA_STRUCTURE, "viewability"),
      editable: get(DATA_STRUCTURE, "editable"),
      preview_url: get(DATA_STRUCTURE, "preview_url"),
      visible: get(DATA_STRUCTURE, "visible", true),
      dco_available: get(DATA_STRUCTURE, "dco_available"),
      dsp_compliant: get(DATA_STRUCTURE, "dsp_compliant"),
      notes: get(DATA_STRUCTURE, "notes"),
      publishers: get(DATA_STRUCTURE, "publishers"),
      platforms: get(DATA_STRUCTURE, "platforms"),
    });
  }
  resetNewsData() {
    this.setState({
      currentNewsSelectedID: null,
      news_date: null,
      news_active: false,
      news_description: "",
      news_title: "Select News",
      news_media: null,
    });
  }

  selectFormat(event, data, key) {
    var format = find(this.props.formats, { _id: data.value });
    this.setState({
      selectedFormat: data.text,
      saved: true,
      width: get(format, "width", ""),
      height: get(format, "height", ""),
      currentSelectedID: get(data, "value", false),
      click: true,
      desktop: get(format, "desktop", false),
      responsive: get(format, "responsive", false),
      mobile: get(format, "mobile", false),
      title: get(format, "title", ""),
      description: get(format, "description", ""),
      visible: get(format, "visible", false),
      zip_html5: get(format, "zip_html5", ""),
      zip_edge: get(format, "zip_edge", ""),
      zip_animate: get(format, "zip_animate", ""),
      zip_gwd: get(format, "zip_gwd", ""),
      pdf: get(format, "pdf", ""),
      expand: get(format, "expand", false),
      video: get(format, "video", false),
      video_progress_metrics: get(format, "video_progress_metrics", false),
      viewability: get(format, "viewability", false),
      editable: get(format, "editable", false),
      preview_url: get(format, "preview_url", ""),
      dco_available: get(format, "dco_available", false),
      dsp_compliant: get(format, "dsp_compliant", false),
      notes: get(format, "notes", ""),
      publishers: get(format, "publishers", []),
      platforms: get(format, "platforms", []),
    });
  }
  selectNews(event, data, key) {
    console.log(data.value);
    var news = find(this.props.news, { _id: data.value });
    this.setState({
      currentNewsSelectedID: get(data, "value", false),
      news_description: get(news, "news_description", ""),
      news_title: get(news, "news_title", ""),
      news_date: get(news, "news_date", ""),
      news_active: get(news, "news_active", false),
      news_media: get(news, "news_media", ""),
      news_url: get(news, "news_url", ""),
    });
  }

  saveDocument() {
    this.setState({ loading: true });
    var data = cloneDeep(this.state);

    unset(data, "id");
    unset(data, "news_title");
    unset(data, "currentSelectedID");
    unset(data, "editor");
    unset(data, "currentNewsSelectedID");
    unset(data, "loading");
    unset(data, "resetdata");
    unset(data, "insertloading");
    unset(data, "refreshloading");
    unset(data, "deleteloading");
    unset(data, "selectedFormat");
    unset(data, "news_description");
    unset(data, "news_media");
    unset(data, "news_active");
    unset(data, "news_date");
    unset(data, "news_title");
    unset(data, "news_url");
    unset(data, "saved");

    console.log(data);
    this.props.saveDocument(this.state.currentSelectedID, data, (res) => {
      console.log(res);
      canSave = false;
      this.setState({ loading: false, saved: true });
      this.loadFormats();
    });
  }

  saveNewsDocument() {
    this.setState({ loading: true });
    var data = {};

    set(data, "news_title", this.state.news_title);
    set(data, "news_date", moment().format("YYYY-MM-DD"));
    set(data, "news_description", this.state.news_description);
    set(data, "news_url", this.state.news_url);
    set(data, "news_active", this.state.news_active);
    set(data, "news_media", this.state.news_media);

    this.props.saveNewsDocument(this.state.currentNewsSelectedID, data, (res) => {
      this.setState({ loading: false, saved: true });
      this.loadNewsFormats();
    });
  }

  formatDataFormats() {
    var array = [];
    each(this.props.formats, (format, index) => {
      array.push({ key: index, text: get(format, "title", null), value: get(format, "_id", null) });
    });
    return array;
  }

  formatDataNews() {
    var array = [];
    each(this.props.news, (news, index) => {
      array.push({ key: index, text: get(news, "news_title", null), value: get(news, "_id", null) });
    });
    return array;
  }

  handleChange(e, data) {
    console.log(e.target.id, data.value);
    if (parseInt(data.value) < 1) {
      data.value = 1;
    }
    var obj = {};
    obj[e.target.id] = data.value;
    obj["saved"] = false;
    this.setState(obj);
    setTimeout(() => {
      if (parseInt(this.state.width) == 1 && parseInt(this.state.height) == 1 && this.state.editor === "formats") {
        this.setState({ responsive: true });
      }
    }, 1000);
  }
  selectFormatMultiple(e, data) {
    console.log(e.currentTarget.id, data.value);
    var obj = {};
    obj["publishers"] = data.value;
    obj["saved"] = false;
    this.setState(obj);
  }
  handleChangeCheck(e, data) {
    console.log(e.target.id, data.checked);
    var obj = {};
    obj[e.target.id] = data.checked;
    obj["saved"] = false;
    this.setState(obj);
  }
  toggleEditor() {
    this.props.toggleEditorMenu(!this.props.editor);
  }

  render() {
    var errorName = false;
    var errorDescription = false;
    var errorPreviewURL = false;

    if (this.state.preview_url.length < 1 || this.state.description.length < 1 || this.state.title === "New Format" || this.state.title.length < 1) {
      if (this.state.title === "New Format" || this.state.title.length < 1) {
        errorName = true;
      }
      if (this.state.preview_url.length < 1 || find(this.props.formats, { preview_url: this.state.preview_url }) !== undefined) {
        errorPreviewURL = true;
      }
      if (this.state.description.length < 1) {
        errorDescription = true;
      }
      canSave = false;
    } else {
      if (this.state.saved && this.state.currentSelectedID === null) {
        canSave = false;
      } else if (this.state.currentSelectedID === null) {
        canSave = false;
      } else if (this.state.saved) {
        canSave = false;
      } else {
        canSave = true;
      }
    }
    return (
      <div id="AdminEditor">
        <Grid relaxed={true} stretched={true} padded={true}>
          <Grid.Row>
            <Grid.Column>
              <Form size={"small"}>
                <Form.Group inline={true}>
                  <Form.Field width={15}>
                    <label>News</label>
                    <Checkbox slider={true} onChange={this.handleEditorType.bind(this)} checked={this.state.editor === "formats"} />
                    <label style={{ marginLeft: 10 }}>Formats</label>
                    <Button className={"toggle-editor"} onClick={this.toggleEditor.bind(this)} floated={"right"}>
                      Toggle Editor
                    </Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className={this.state.editor === "formats" ? "show" : "hide"}>
            <Grid.Column>
              <Menu stackable fluid={true} size={"small"}>
                <Menu.Item>
                  <Button
                    disabled={false}
                    loading={this.state.refreshloading}
                    onClick={this.props.loadDocuments.bind(this, "formats", (res) => {
                      console.log("Formats reloaded");
                    })}>
                    <Icon name={"refresh"} /> Load Formats
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button loading={this.state.insertloading} icon={true} color={"olive"} disabled={false} onClick={this.insertFormat.bind(this)}>
                    <Icon name={"plus"} /> Insert Format
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Dropdown
                    clearable={true}
                    compact={false}
                    button={true}
                    fluid={false}
                    placeholder={this.state.title !== "" ? this.state.title : "Select Format"}
                    onChange={this.selectFormat.bind(this)}
                    scrolling
                    ref={"dropdown"}
                    value={this.state.selectedFormat}
                    size={"mini"}
                    selection
                    disabled={this.props.formats.length < 1 ? true : false}
                    options={this.formatDataFormats()}
                  />
                </Menu.Item>
                <Menu.Item>
                  <Button
                    color={"blue"}
                    disabled={this.state.currentSelectedID === null}
                    loading={this.state.insertloading}
                    onClick={this.duplicateFormat.bind(this)}>
                    <Icon name={"copy"} /> Duplicate
                  </Button>
                </Menu.Item>
                <Menu.Menu position={"right"} size={"mini"}>
                  <Menu.Item>
                    <Button color={"facebook"} disabled={!canSave} loading={this.state.loading} onClick={this.saveDocument.bind(this)}>
                      <Icon name={"save"} /> Save Format
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      color={"red"}
                      loading={this.state.deleteloading}
                      disabled={this.state.currentSelectedID ? false : true}
                      onClick={this.deleteDocument.bind(this)}>
                      <Icon name={"trash"} /> Delete Format
                    </Button>
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={this.state.editor === "formats" ? "show" : "hide"}>
            <Grid.Column tablet={4} computer={4} mobile={4}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={4}>
                    <label>Width</label>
                    <Input min={1} id="width" onChange={this.handleChange.bind(this)} value={this.state.width} type="number" />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={4}>
                    <label>Height</label>
                    <Input min={1} id="height" onChange={this.handleChange.bind(this)} value={this.state.height} type="number" />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column tablet={12} computer={12} mobile={12}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field error={errorName} disabled={this.state.currentSelectedID ? false : true} width={4}>
                    <label>Name</label>
                    <Input id="title" onChange={this.handleChange.bind(this)} value={this.state.title} type="text" />
                  </Form.Field>
                  <Form.Field error={errorDescription} disabled={this.state.currentSelectedID ? false : true} width={12}>
                    <label>Description</label>
                    <Input id="description" onChange={this.handleChange.bind(this)} value={this.state.description} type="text" />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={this.state.editor === "formats" ? "show" : "hide"} verticalAlign={"middle"}>
            <Grid.Column tablet={8} computer={8} mobile={8} verticalAlign={"bottom"}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>Desktop</label>
                    <Checkbox id="desktop" onChange={this.handleChangeCheck.bind(this)} toggle checked={this.state.desktop} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>Mobile</label>
                    <Checkbox id="mobile" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.mobile} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>Responsive</label>
                    <Checkbox id="responsive" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.responsive} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>Expand</label>
                    <Checkbox id="expand" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.expand} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={1}>
                    <label>Editable</label>
                    <Checkbox id="editable" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.editable} />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column tablet={8} computer={8} mobile={8} verticalAlign={"bottom"}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>Video</label>
                    <Checkbox id="video" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.video} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>Click implemented</label>
                    <Checkbox id="click" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.click} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>DCO</label>
                    <Checkbox id="dco_available" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.dco_available} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>Programmatic</label>
                    <Checkbox id="dsp_compliant" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.dsp_compliant} />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={2}>
                    <label>WeboDesign Visibility</label>
                    <Checkbox id="visible" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.visible} />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={this.state.editor === "formats" ? "show" : "hide"} tablet={8} computer={8} mobile={8} verticalAlign={"middle"}>
            <Grid.Column verticalAlign={"bottom"}>
              <Form size={"small"}>
                <Form.Group inline={false}>
                  <Form.Field error={errorPreviewURL} disabled={this.state.currentSelectedID ? false : true} width={4}>
                    <label>Preview URL</label>
                    <Input id="preview_url" onChange={this.handleChange.bind(this)} value={this.state.preview_url} type="text" />
                  </Form.Field>

                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={4}>
                    <label>PDF URL</label>
                    <Input id="pdf" onChange={this.handleChange.bind(this)} value={this.state.pdf} type="text" />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={4}>
                    <label>Notes</label>
                    <Input id="notes" onChange={this.handleChange.bind(this)} value={this.state.notes} type="text" />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID ? false : true} width={4}>
                    <label>Publishers</label>
                    <Dropdown
                      multiple={true}
                      button={true}
                      fluid={false}
                      placeholder="Select Publishers"
                      floating={true}
                      id="publishers"
                      onChange={this.selectFormatMultiple.bind(this)}
                      scrolling
                      value={this.state.publishers}
                      size={"tiny"}
                      selection
                      disabled={false}
                      options={PUBLISHERS}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={this.state.editor === "formats" ? "show" : "hide"} tablet={8} computer={8} mobile={8} verticalAlign={"middle"}>
            <Grid.Column verticalAlign={"bottom"}>
              <Form size={"small"}>
                <Form.Group inline={false}>
                  <Form.Field disabled={this.state.currentSelectedID && this.state.editable ? false : true} width={4}>
                    <label>ZIP URL (HTML5)</label>
                    <Input id="zip_html5" onChange={this.handleChange.bind(this)} value={this.state.zip_html5} type="text" />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID && this.state.editable ? false : true} width={4}>
                    <label>ZIP URL (Edge)</label>
                    <Input id="zip_edge" onChange={this.handleChange.bind(this)} value={this.state.zip_edge} type="text" />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID && this.state.editable ? false : true} width={4}>
                    <label>ZIP URL (Animate)</label>
                    <Input id="zip_animate" onChange={this.handleChange.bind(this)} value={this.state.zip_animate} type="text" />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentSelectedID && this.state.editable ? false : true} width={4}>
                    <label>ZIP URL (Google Web Designer)</label>
                    <Input id="zip_gwd" onChange={this.handleChange.bind(this)} value={this.state.zip_gwd} type="text" />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>

          {/*NEWS*/}

          <Grid.Row className={this.state.editor === "news" ? "show" : "hide"}>
            <Grid.Column>
              <Menu stackable fluid={true} size={"small"}>
                <Menu.Item>
                  <Button
                    disabled={false}
                    loading={this.state.refreshloading}
                    onClick={this.props.loadNewsDocuments.bind(this, "news", (res) => {
                      console.log("News reloaded");
                    })}>
                    <Icon name={"refresh"} /> Load News
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button loading={this.state.insertloading} icon={true} color={"olive"} disabled={false} onClick={this.insertNewsFormat.bind(this)}>
                    <Icon name={"plus"} /> Insert News
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Dropdown
                    clearable={true}
                    compact={false}
                    button={true}
                    fluid={false}
                    placeholder={this.state.news_title !== "" ? this.state.news_title : "Select News"}
                    onChange={this.selectNews.bind(this)}
                    scrolling
                    ref={"dropdown"}
                    value={this.state.selectedNews}
                    size={"mini"}
                    selection
                    disabled={this.props.news.length < 1 ? true : false}
                    options={this.formatDataNews()}
                  />
                </Menu.Item>

                <Menu.Menu position={"right"} size={"mini"}>
                  <Menu.Item>
                    <Button
                      color={"facebook"}
                      disabled={this.state.currentNewsSelectedID ? false : true}
                      loading={this.state.loading}
                      onClick={this.saveNewsDocument.bind(this)}>
                      <Icon name={"save"} /> Save News
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      color={"red"}
                      loading={this.state.deleteloading}
                      disabled={this.state.currentNewsSelectedID ? false : true}
                      onClick={this.deleteNewsDocument.bind(this)}>
                      <Icon name={"trash"} /> Delete News
                    </Button>
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={this.state.editor === "news" ? "show" : "hide"}>
            <Grid.Column tablet={4} computer={4} mobile={4}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field disabled={this.state.currentNewsSelectedID ? false : true} width={4}>
                    <label>Title</label>
                    <Input min={1} id="news_title" onChange={this.handleChange.bind(this)} value={this.state.news_title} type="text" />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column tablet={12} computer={12} mobile={12}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field disabled={this.state.currentNewsSelectedID ? false : true} width={12}>
                    <label>Description</label>
                    <Input id="news_description" onChange={this.handleChange.bind(this)} value={this.state.news_description} type="text" />
                  </Form.Field>
                  <Form.Field disabled={this.state.currentNewsSelectedID ? false : true} width={2}>
                    <label>Active</label>
                    <Checkbox id="news_active" toggle onChange={this.handleChangeCheck.bind(this)} checked={this.state.news_active} />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={this.state.editor === "news" ? "show" : "hide"}>
            <Grid.Column tablet={8} computer={8} mobile={8}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field disabled={this.state.currentNewsSelectedID ? false : true} width={4}>
                    <label>URL Preview</label>
                    <Input min={1} id="news_url" onChange={this.handleChange.bind(this)} value={this.state.news_url} type="text" />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column tablet={8} computer={8} mobile={8}>
              <Form size={"small"}>
                <Form.Group widths={"equal"} inline={false}>
                  <Form.Field disabled={this.state.currentNewsSelectedID ? false : true} width={4}>
                    <label>URL Media (image or video)</label>
                    <Input min={1} id="news_media" onChange={this.handleChange.bind(this)} value={this.state.news_media} type="text" />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    editor: state.Reducer.App.editor,
    news: state.Reducer.App.news,
    formats: state.Reducer.App.formats,
    section: state.Reducer.App.section,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEditorMenu(show) {
      dispatch(toggleEditorMenuAction(show));
    },
    saveDocument: (uuid, data, cb) => {
      dispatch(
        request(
          SAVE_FORMATS.url + "/" + uuid,
          saveFormatsAction,
          {
            method: SAVE_FORMATS.method,
            bodyData: data,
          },
          cb,
        ),
      );
    },
    deleteDocument: (uuid, cb) => {
      dispatch(
        request(
          DELETE_FORMATS.url + "/" + uuid,
          deleteFormatsAction,
          {
            method: DELETE_FORMATS.method,
          },
          cb,
        ),
      );
    },
    createDocument: (data, cb) => {
      console.log(data);
      dispatch(
        request(
          CREATE_FORMATS.url,
          createFormatsAction,
          {
            method: CREATE_FORMATS.method,
            bodyData: data,
          },
          cb,
        ),
      );
    },
    loadDocuments: (cb) => {
      dispatch(
        request(
          GET_FORMATS.url,
          getFormatsAction,
          {
            method: GET_FORMATS.method,
          },
          cb,
        ),
      );
    },
    saveNewsDocument: (uuid, data, cb) => {
      dispatch(
        request(
          SAVE_NEWS.url + "/" + uuid,
          saveNewsFormatsAction,
          {
            method: SAVE_NEWS.method,
            bodyData: data,
          },
          cb,
        ),
      );
    },
    deleteNewsDocument: (uuid, cb) => {
      dispatch(
        request(
          DELETE_NEWS.url + "/" + uuid,
          deleteNewsFormatsAction,
          {
            method: DELETE_NEWS.method,
          },
          cb,
        ),
      );
    },
    createNewsDocument: (data, cb) => {
      dispatch(
        request(
          CREATE_NEWS.url,
          createNewsFormatsAction,
          {
            method: CREATE_NEWS.method,
            bodyData: data,
          },
          cb,
        ),
      );
    },
    loadNewsDocuments: (cb) => {
      dispatch(
        request(
          GET_NEWS.url,
          getNewsFormatsAction,
          {
            method: GET_NEWS.method,
          },
          cb,
        ),
      );
    },

    // getFormats: function() {
    //     dispatch(getFormatsAction(true));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditor);
