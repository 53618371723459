import React, { Component } from "react";
import { connect } from "react-redux";
import { cloneDeep, each, get, isNil, map } from "lodash";
import { STANDARD_FORMATS } from "./Constants.js";
import { Dropdown, Form, Grid, Button, Icon, Input } from "semantic-ui-react";
import {
  setClientNameAction,
  setFeedDataAction,
  setFeedIDAction,
  setSelectedRuleAction,
  loadFlushIframeAction,
  setRulesDataAction,
  setRulesStatusAction,
  setFormatsAction,
  setFeedStatusAction,
  setRetargetingValueAction,
} from "./Actions.js";
import FormatPreview from "./FormatPreview.jsx";
class DCOTester extends Component {
  constructor(props) {
    super(props);
    this.state = { last_page: "", retargeting_value: "", random: "0" };
  }

  setFormatsToDisplay(e, data) {
    var hash = "";
    each(data.value, (h) => {
      hash += h + "|";
    });
    window.location.hash = hash.substring(0, hash.length - 1);
    this.props.setFormats(data.value);
  }

  reload() {
    var formatsSelected = cloneDeep(this.props.formats);
    this.props.setFormats([]);
    setTimeout(() => {
      this.props.setFormats(formatsSelected);
      this.loadRules();
      this.setState({ random: Math.round(Math.random() * 100000000) });
    }, 100);
  }
  loadRules() {
    if (this.props.rulesID.length > 0 || this.props.location.search) {
      var id = this.props.rulesID || this.props.location.search.replace("?rule=", "");
      fetch("https://ams3.digitaloceanspaces.com/weborama/rules/" + id + ".json")
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.props.setRulesData(json);
        })
        .catch((e) => {
          console.log(e);
          this.props.setRulesStatus(false);
        });
    }
  }
  setData() {
    console.log(this.props.match.params);
    if (this.props.match.params.client) {
      this.props.setClientName(this.props.match.params.client);
    }

    if (this.props.match.params.feed_id) {
      fetch("https://ams3.digitaloceanspaces.com/weborama/feeds/" + this.props.match.params.feed_id + ".json")
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.props.setFeedData(json);
        })
        .catch((e) => {
          console.log(e);
          this.props.setFeedStatus(false);
        });
    }

    if (this.props.location.hash.length > 1) {
      var formats = this.props.location.hash.replace("#", "");
      formats = formats.split("|");
      console.log(formats);
      this.props.setFormats(formats);
    }
  }
  hashChanged() {
    console.log("hash changed");
  }
  postedMessage(event) {
    if (event.origin === "https://ams3.digitaloceanspaces.com") {
      if (get(event.data, "retargeting", false)) {
        this.props.setRetargetingValue(event.data.retargeting);
      }
      if (get(event.data, "flushed", false)) {
        this.props.loadFlushIframe(false);
        this.props.setRetargetingValue("");
        setTimeout(() => {
          this.reload();
        }, 100);
      }
    }
  }
  setActiveRule(e, data) {
    this.props.setSelectedRule(data.value);
  }
  loadRule(e, data) {
    let params = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=400,left=0,top=0";
    let newWindow = window.open(this.props.selectedRule + "?wbo=true", "Popup", params);
    var x = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(x);
        this.setState({ random: Math.round(Math.random() * 100000000000) });
      }
    }, 200);
  }
  cleanRules(e, data) {
    this.props.loadFlushIframe(true);
  }
  componentDidMount() {
    window.addEventListener("message", this.postedMessage.bind(this), false);
    window.addEventListener("hashchange", this.hashChanged.bind(this), false);
    this.setData();
    this.loadRules();
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.postedMessage.bind(this));
    window.removeEventListener("hashchange", this.hashChanged.bind(this));
  }

  loaded(e) {
    console.log(e, "loaded iframe");
  }

  render() {
    return (
      <Grid className="DCOTester" padded>
        <Grid.Row>
          <Grid.Column tablet={14} computer={14} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Dropdown}
                  width={4}
                  multiple={true}
                  search={true}
                  label={"Formats"}
                  clearable={true}
                  selection={true}
                  value={this.props.formats}
                  onChange={this.setFormatsToDisplay.bind(this)}
                  options={map(STANDARD_FORMATS, (format) => {
                    return { value: format.value, text: format.text, key: format.key };
                  })}
                />
                <Form.Field
                  control={Dropdown}
                  width={4}
                  basic
                  search={true}
                  label={"Rules"}
                  selection={true}
                  onChange={this.setActiveRule.bind(this)}
                  multiple={false}
                  options={map(get(this.props.rulesData, "rules", []), (rule, index) => {
                    return { value: rule.section, text: rule.name, key: index };
                  })}
                />
                <Form.Field control={Button} width={3} label={"Load"} disabled={isNil(this.props.selectedRule)} color={"blue"} onClick={this.loadRule.bind(this)}>
                  Load Rule
                  <Icon name="arrow right" />
                </Form.Field>
                <Form.Field control={Button} width={3} label={"Clean"} color={"red"} onClick={this.cleanRules.bind(this)}>
                  <Icon name="close" />
                  Clean Rules
                  {this.props.cleanRulesIframe ? <iframe frameBorder="0" src={"https://ams3.digitaloceanspaces.com/weborama/dco/flush_ls.html"} width="1" height="1" /> : null}
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Field control={Input} width={16} readOnly={true} value={this.props.retargeting_value} label={"Last Page Visited"} />
                <iframe src={"https://ams3.digitaloceanspaces.com/weborama/dco/check_ls.html?random=" + this.state.random} frameBorder="0" width="1" height="1" />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={4} computer={4} mobile={16}>
            <Form.Field
              control={Button}
              width={2}
              content={"Refresh Banners"}
              disabled={this.props.formats.length < 1 ? true : false}
              color={"olive"}
              icon={"refresh"}
              onClick={this.reload.bind(this)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form.Field control={"div"} className={"type"} label={"Squared"}>
              {this.props.formats.indexOf("333x280") >= 0 ? <FormatPreview format={"333x280"} /> : null}
              {this.props.formats.indexOf("300x250") >= 0 ? <FormatPreview format={"300x250"} /> : null}
              {this.props.formats.indexOf("250x250") >= 0 ? <FormatPreview format={"250x250"} /> : null}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form.Field control={"div"} className={"type"} label={"Horizontal"}>
              {this.props.formats.indexOf("970x250") >= 0 ? <FormatPreview format={"970x250"} /> : null}
              {this.props.formats.indexOf("728x90") >= 0 ? <FormatPreview format={"728x90"} /> : null}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form.Field control={"div"} className={"type"} label={"Vertical"}>
              {this.props.formats.indexOf("160x600") >= 0 ? <FormatPreview format={"160x600"} /> : null}
              {this.props.formats.indexOf("120x600") >= 0 ? <FormatPreview format={"120x600"} /> : null}
              {this.props.formats.indexOf("300x600") >= 0 ? <FormatPreview format={"300x600"} /> : null}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form.Field control={"div"} className={"type"} label={"Mobile"}>
              {this.props.formats.indexOf("320x50") >= 0 ? <FormatPreview format={"320x50"} /> : null}
              {this.props.formats.indexOf("320x480") >= 0 ? <FormatPreview format={"320x480"} /> : null}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    cleanRulesIframe: state.Reducer.DCO.cleanRulesIframe,
    selectedRule: state.Reducer.DCO.selectedRule,
    rulesData: state.Reducer.DCO.rulesData,
    rulesStatus: state.Reducer.DCO.rulesStatus,
    rulesID: state.Reducer.DCO.rulesID,
    feedStatus: state.Reducer.DCO.feedStatus,
    retargeting_value: state.Reducer.DCO.retargeting_value,
    feedData: state.Reducer.DCO.feedData,
    client: state.Reducer.DCO.client,
    formats: state.Reducer.DCO.formats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRetargetingValue: (retargeting_value) => {
      dispatch(setRetargetingValueAction(retargeting_value));
    },
    setSelectedRule: (rule) => {
      dispatch(setSelectedRuleAction(rule));
    },
    setRulesStatus: (status) => {
      dispatch(setRulesStatusAction(status));
    },
    setRulesData: (rules) => {
      dispatch(setRulesDataAction(rules));
    },
    setClientName: (name) => {
      dispatch(setClientNameAction(name));
    },
    loadFlushIframe: (show) => {
      dispatch(loadFlushIframeAction(show));
    },
    setFeedID: (id) => {
      dispatch(setFeedIDAction(id));
    },
    setFeedData: (feedData) => {
      dispatch(setFeedDataAction(feedData));
    },
    setFeedStatus: (status) => {
      dispatch(setFeedStatusAction(status));
    },
    setFormats: (formats) => {
      dispatch(setFormatsAction(formats));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DCOTester);
