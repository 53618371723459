import { combineReducers } from "redux";

function App(
  state = {
    section: "Home",
    news: [],
    formats: [],
    format: {},
    editor: false,
    html: "",
    formatsDisabled: true,
  },
  action
) {
  switch (action.type) {
    case "GET_FORMATS":
      return Object.assign({}, state, {
        formats: action.response,
        formatsDisabled: false,
      });

    case "SET_FORMAT":
      return Object.assign({}, state, {
        format: action.format,
      });
    case "GET_NEWS":
      return Object.assign({}, state, {
        news: action.response,
      });
    case "SET_HTML_TAG":
      return Object.assign({}, state, {
        html: action.html,
      });
    case "SET_SECTION":
      return Object.assign({}, state, {
        section: action.section,
      });
    case "SERVER_MESSAGE":
      if (action.response.logged !== undefined) {
        return Object.assign({}, state, {
          logged: action.response.logged,
        });
      } else {
        return state;
      }
    case "ERROR":
      return Object.assign({}, state, {
        logged: false,
      });
    case "TOGGLE_EDITOR_MENU":
      return Object.assign({}, state, {
        editor: action.show,
      });

    case "GET_DATA":
      return Object.assign({}, state, {
        data: action.response.result,
      });

    default:
      return state;
  }
}

function Feed(
  state = {
    data: {},
    showFeedModal: false,
    showSplitTools: false,
    feedName: "",
    feedUUID: "",
    uploadOutputData: null,
  },
  action
) {
  switch (action.type) {
    case "GET_FEED":
      return Object.assign({}, state, {
        data: action.response,
      });
    case "TOGGLE_SPLIT_TOOLS":
      return Object.assign({}, state, {
        showSplitTools: action.show,
      });
    case "SET_FEED":
      return Object.assign({}, state, {
        data: action.response,
      });
    case "SET_FEED_NAME":
      return Object.assign({}, state, {
        feedName: action.name.toLowerCase(),
      });
    case "SET_FEED_UUID":
      return Object.assign({}, state, {
        feedUUID: action.uuid,
      });

    case "TOGGLE_FEED_MODAL":
      return Object.assign({}, state, {
        uploadOutputData: action.data,
        showFeedModal: action.show,
      });
    default:
      return state;
  }
}
function RichMedia(
  state = {
    data: {},
    showFormatModal: false,
    richMediaFormat: null,
    richMediaUUID: '',
    uploadOutputData: null,
  },
  action
) {
  switch (action.type) {
    case "GET_RICHMEDIA":
      return Object.assign({}, state, {
        data: action.response,
      });
    case "SET_RICHMEDIA_FORMAT":
      return Object.assign({}, state, {
        richMediaFormat: action.format,
      });
    case "SET_DATA_RICHMEDIA":
      return Object.assign({}, state, {
        data: action.response,
      });
    case "SET_RICHMEDIA_UUID":
      return Object.assign({}, state, {
        richMediaUUID: action.uuid,
      });
    case "TOGGLE_RICHMEDIA_MODAL":
      return Object.assign({}, state, {
        uploadOutputData: action.data,
        showFormatModal: action.show,
      });
    default:
      return state;
  }
}
function Board(
  state = {
    wamID: "",
    rules: [],
    boardLabel: "",
  },
  action
) {
  switch (action.type) {
    case "SET_WAM_ID":
      return Object.assign({}, state, {
        wamID: action.id,
      });
    case "SET_LABEL":
      return Object.assign({}, state, {
        boardLabel: action.label,
      });
    case "ADD_RULE":
      return Object.assign({}, state, {
        rules: action.rules,
      });
    case "DELETE_RULE":
      return Object.assign({}, state, {
        rules: action.rules,
      });
    case "SAVE_RULES":
      return Object.assign({}, state, {
        rules: action.rules,
      });
    case "SET_RULES":
      return Object.assign({}, state, {
        rules: action.rules,
      });
    default:
      return state;
  }
}
function DCO(
  state = {
    feedData: null,
    feedID: "",
    selectedRule: null,
    cleanRulesIframe: false,
    feedStatus: null,
    rulesID: "",
    rulesStatus: true,
    rulesData: null,
    retargeting_value: "",
    client: null,
    formats: [],
  },
  action
) {
  switch (action.type) {
    case "SET_FEED_DATA":
      return Object.assign({}, state, {
        feedData: action.data,
      });
    case "LOAD_FLUSH_IFRAME":
      return Object.assign({}, state, {
        cleanRulesIframe: action.show,
      });
    case "SET_SELECTED_RULE":
      return Object.assign({}, state, {
        selectedRule: action.rule,
      });
    case "SET_RULES_ID":
      return Object.assign({}, state, {
        rulesID: action.id,
      });
    case "SET_RULES_STATUS":
      return Object.assign({}, state, {
        rulesStatus: action.status,
      });
    case "SET_RULES_DATA":
      return Object.assign({}, state, {
        rulesData: action.rules,
      });
    case "SET_FEED_ID":
      return Object.assign({}, state, {
        feedID: action.id,
      });
    case "SET_RETARGETING_VALUE":
      return Object.assign({}, state, {
        retargeting_value: action.retargeting_value,
      });
    case "SET_FEED_STATUS":
      return Object.assign({}, state, {
        feedStatus: action.status,
      });
    case "SET_FORMATS":
      return Object.assign({}, state, {
        formats: action.formats,
      });
    case "SET_CLIENT_NAME":
      return Object.assign({}, state, {
        client: action.name.toLowerCase(),
      });
    default:
      return state;
  }
}

const state = combineReducers({
  App,
  Board,
  DCO,
  RichMedia,
  Feed,
});

export default state;
