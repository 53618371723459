import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Icon, Dropdown, Segment, Button, Form, Header, Input, TextArea, Modal } from "semantic-ui-react";
import { setHTMLAction } from "./Actions.js";
import { each, cloneDeep, map } from "lodash";
import { AD_SERVERS_TAGS } from "./Constants";
import { CSVLink } from "react-csv";

const options = [
  { key: 1, text: "Impression", value: "impression" },
  { key: 2, text: "Click", value: "click" },
];

class TagPreviewer extends Component {
  constructor(props) {
    super(props);
    this.csvtemplate =
      'Creative name,Dimensions (width x height),Third-party tag,Landing page URL,Expanding direction,"Expands on hover (""Yes"" or ""No"")","Requires HTML5 (""Yes"" or ""No"")","Requires MRAID (""Yes"" or ""No"")",CM Tracking Placement ID,Integration code (Optional),Notes (Optional)\n';

    this.state = {
      iframestatus: "minimized",
      replaceOptions: [],
      bulkUpload: false,
      temporaryHTML: "",
      parallelImp: [],
      imp: "",
      lp: "",
      click: "",
      note: false,
      csvdata: "",
      csvtitle: "",
      parallelClick: [],
      pigType: "",
      modal: false,
      adserver: "",
    };
  }

  factorDropdownOptions() {
    var array = [];
    each(AD_SERVERS_TAGS, (tech, index) => {
      array.push({ value: tech.name, text: tech.name });
    });
    this.setState({ replaceOptions: array });
  }

  transformTag() {
    console.log("transform tag");
    var value = this.state.temporaryHTML.replace(/\[RANDOM]/g, AD_SERVERS_TAGS[this.state.adserver]["CB"]);
    value = value.replace(/\[PUBLISHER_TRACKING_URL]/g, AD_SERVERS_TAGS[this.state.adserver]["CLICK"]);
    value = value.replace(/\[ENCODED_PUBLISHER_TRACKING_URL]/g, AD_SERVERS_TAGS[this.state.adserver]["CLICK_ENC"]);
    if (AD_SERVERS_TAGS[this.state.adserver]["ENC"]) {
      value = value.replace(/\,imptrackers/g, ",weak_encoding:0\n ,imptrackers");
    }
    if (this.state.secureMode) {
      value = value.replace(/\http:+/g, "https:");
    }
    if (this.state.isMobile) {
      value = value.replace(/\g.ism=0/g, "g.ism=1");
    }
    this.props.setHTML(value);
    this.setState({ temporaryHTML: value });
  }

  removeImpTracker(value) {
    console.log("remove imp tracker");
    console.log(value, this.state.parallelImp);
    var html = cloneDeep(this.props.html);
    var array = [];
    each(this.state.parallelImp, (tracker, index) => {
      console.log(value, tracker);
      if (value !== tracker) {
        array.push(tracker);
      }
    });

    html = html.replace(/\,imptrackers[^$].*/g, ",imptrackers          : [" + array.toString() + "]");
    html = html.replace(",]", "]");
    this.props.setHTML("");
    setTimeout(() => {
      this.setState({ parallelImp: array });
      this.props.setHTML(html);
    }, 500);
  }

  removeClickTracker(value) {
    console.log("remove click tracker");
    var html = cloneDeep(this.props.html);
    var array = [];
    each(this.state.parallelClick, (tracker, index) => {
      console.log(tracker, value);
      if (value !== tracker) {
        array.push(tracker);
      }
    });
    html = html.replace(/\,imptrackers[^$].*/g, ",imptrackers          : [" + array.toString() + "]");
    html = html.replace(",]", "]");
    this.props.setHTML("");
    setTimeout(() => {
      this.setState({ parallelClick: array });
      this.props.setHTML(html);
    }, 500);
  }
  save() {
    console.log("save");
    var html = cloneDeep(this.props.html);
    html = html.replace(/\,imptrackers[^$].*/g, ",imptrackers          : ['" + this.state.parallelImp.toString() + "']");
    html = html.replace(",]", "]");
    html = html.replace("\"'", '"');
    html = html.replace("'\"", '"');
    html = html.replace(/\,clicktrackers[^$].*/g, ",clicktrackers          : ['" + this.state.parallelClick.toString() + "']");
    html = html.replace(",]", "]");
    html = html.replace("\"'", '"');
    html = html.replace("'\"", '"');
    html = html.replace("['']", "[]");
    html = html.replace("['']", "[]");

    this.props.setHTML("");
    setTimeout(() => {
      this.props.setHTML(html);
    }, 500);
  }

  addImpTracker() {
    var array = cloneDeep(this.state.parallelImp);
    array.push('"' + this.state.imp + '"');
    this.setState({ parallelImp: array, imp: "" });
  }
  addClickTracker() {
    var array = cloneDeep(this.state.parallelClick);
    array.push('"' + this.state.click + '"');
    this.setState({ parallelClick: array, click: "" });
  }
  clean() {
    console.log("clean");
    var html = cloneDeep(this.props.html);
    html = html.replace(/\,imptrackers[^$].*/g, ",imptrackers          : ['" + [].toString() + "']");
    html = html.replace(",]", "]");
    html = html.replace("\"'", '"');
    html = html.replace("'\"", '"');
    html = html.replace("['']", "[]");

    html = html.replace(/\,clicktrackers[^$].*/g, ",clicktrackers          : ['" + this.state.parallelClick.toString() + "']");
    html = html.replace(",]", "]");
    html = html.replace("\"'", '"');
    html = html.replace("'\"", '"');
    html = html.replace("['']", "[]");

    this.setState({ parallelClick: [], parallelImp: [], imp: "", click: "" });
  }
  setImpTracker(e, data) {
    this.setState({ imp: data.value });
  }

  setClickTracker(e, data) {
    this.setState({ click: data.value });
  }

  close() {
    this.setState({ modal: true });
  }

  componentDidMount() {
    this.factorDropdownOptions();
  }

  replaceMacro(e, data) {
    switch (data.value) {
      case "Turn":
        this.setState({ adserver: "TURN" });
        break;
      case "Open AdStream":
        this.setState({ adserver: "OPEN ADSTREAM" });
        break;
      case "Smadex":
        this.setState({ adserver: "SMADEX" });
        break;
      case "MediaMath":
        this.setState({ adserver: "MEDIAMATH" });
        break;
      case "AppNexus":
        this.setState({ adserver: "APPNEXUS" });
        break;
      case "Smart Ad-Server":
        this.setState({ adserver: "SMART ADSERVER" });
        break;
      case "AdForm":
        this.setState({ adserver: "ADFORM" });
        break;
      case "DFP":
        this.setState({ adserver: "DFP" });
        break;
      case "DFA":
        this.setState({ adserver: "DFA" });
        break;
      case "DV360":
        this.setState({ adserver: "DBM" });
        break;
      default:
        this.setState({ adserver: "WCM" });
    }

    setTimeout(() => {
      this.transformTag();
    }, 500);
  }

  changeTagText(e, data) {
    this.props.setHTML(data.value);
    this.setState({ temporaryHTML: data.value });
  }
  refresh() {
    var html = cloneDeep(this.props.html);
    this.props.setHTML("");
    setTimeout(() => {
      this.props.setHTML(html);
    }, 500);
  }
  rip() {
    var html = cloneDeep(this.props.html);
    var script = html.split("<noscript")[0];
    this.props.setHTML("");
    setTimeout(() => {
      this.props.setHTML(script);
    }, 500);
  }

  changeBulkMode() {
    this.setState({ bulkUpload: !this.state.bulkUpload });
  }

  removeBadParts(input) {
    var txt = input.replace(/JS :([\S\s]*?)<\/noscript>/g, "");
    txt = txt.replace(/<iframe([\S\s]*?)iframe>/g, "");
    txt = txt.replace(/<noscript([\S\s]*?)noscript>/g, "");
    txt = txt.replace(/<amp([\S\s]*?)pixel>/g, "");
    txt = txt.replace(/<amp([\S\s]*?)pixel>/g, "");
    txt = txt.replace(/<amp([\S\s]*?)ad>/g, "");
    txt = txt.replace(/<a href="http:([\S\s]*?)a>/g, "");
    txt = txt.replace(/IFRAME-SSL :/g, "");
    txt = txt.replace(/IMAGE-SSL :/g, "");
    txt = txt.replace(/AMP :/g, "\n\n");
    txt = txt.replace(/JS-SSL :/g, "");
    txt = txt.replace(/IFRAME :/g, "");
    txt = txt.replace(/VAST :/g, "");
    txt = txt.replace(/IMAGE :/g, "");

    txt = txt.replace(/Tracking tags.*/g, "");
    txt = txt.replace(/\*\*\*\*\*\*\* Ad position 1 \*\*\*\*\*\*\*/g, "");
    txt = txt.replace(/\n\n\n/g, "\n");
    txt = txt.replace(/\n\n/g, "\n");
    txt = txt.replace(/\n\n\n/g, "\n");
    txt = txt.replace(/\n\n/g, "\n");
    txt = txt.replace(/\n\*\*\*\*\*\*/g, "\n\n******");
    txt = txt.replace(/Delivery format: Redirect/g, "Delivery format: Redirect\n");
    txt = txt.replace(/Delivery format: Pixel/g, "Delivery format: Pixel\n");
    txt = txt.replace(/Delivery format: VAST/g, "Delivery format: VAST\n");
    console.log(txt);
    return txt;
  }

  exportCSV() {
    console.log("exporting CSV");
    var txt = this.removeBadParts(this.props.html);
    this.setState({ temporaryHTML: txt });
    this.props.setHTML(txt);
    var title = "Untitled";
    var creatives = this.csvtemplate;
    var insertionname = "";
    each(txt.split("\n\n"), (line, index) => {
      var vast = false;

      if (index === 0) {
        title = line.split("**")[1];
        title = title.replace(" Campaign ", "");
        title = title.replace(" ", "");
        this.csvtitle = title;
      } else {
        if (line.indexOf("****") >= 0) {
          creatives += line.split("******")[1] + ",";
          insertionname = line.split("******")[1] + ",";
        } else {
          if (line.indexOf("<script") >= 0) {
            vast = false;
          } else if (line.indexOf("<img") >= 0) {
            vast = false;
          } else if (line.indexOf("<a") >= 0) {
            vast = false;
          } else {
            vast = true;
          }
          var w = "1";
          var h = "1";
          if (!vast) {
            each(line.split("\n"), (tagline) => {
              if (tagline.indexOf("width") >= 0) {
                w = parseInt(tagline.split(":")[1]);
              }
              if (tagline.indexOf("height") >= 0) {
                h = parseInt(tagline.split(":")[1]);
              }
            });
          }
          creatives += w + "x" + h + ",";
          creatives += JSON.stringify(line.replace(/\n/g, "")).replace(/\\/g, "") + ",";
          creatives += this.state.lp + ",";
          creatives += "None" + ",";
          creatives += "No" + ",";

          creatives += "Yes" + ",";
          if (vast === true) {
            creatives += "Yes" + ",";
          } else {
            creatives += "No" + ",";
          }

          if (this.state.note) {
            creatives += "" + ",";
            creatives += "" + ",";
            console.log(insertionname);
            console.log(insertionname.split("_"));
            if (insertionname.split("_")[5] !== undefined) {
              creatives += insertionname.split("_")[5].toUpperCase() + "\n";
            } else {
              creatives += "" + "\n";
            }
          } else {
            creatives += "" + ",";
            creatives += "" + ",";
            creatives += "" + "\n";
          }
        }
      }
    });

    this.setState({ csvtitle: title, csvdata: creatives });
  }
  changeLP(e, data) {
    this.setState({ lp: data.value });
  }
  setNote() {
    this.setState({ note: !this.state.note });
  }
  piggyback() {
    this.setState({ modal: true });
  }
  showPiggybackInput(e, data) {
    this.setState({ pigType: data.value });
  }
  toggleIframeStatus() {
    if (this.state.iframestatus == "minimized") {
      this.setState({ iframestatus: "full" });
    } else {
      this.setState({ iframestatus: "minimized" });
    }
  }

  copyStringToClipboard() {
    document.oncopy = (event) => {
      event.clipboardData.setData("text/plain", this.props.html);
      event.preventDefault();
    };
    document.execCommand("copy");
  }
  closeModal() {
    this.setState({ modal: false });
  }
  render() {
    return (
      <Grid className="TagPreviewer" padded>
        <Grid.Row>
          <Grid.Column tablet={this.state.bulkUpload ? 16 : 8} computer={this.state.bulkUpload ? 16 : 8} mobile={16}>
            <Segment compact padded={false} basic={true}>
              <Button disabled={this.props.html === "" || this.state.bulkUpload ? true : false} onClick={this.rip.bind(this)}>
                <Icon name={"cut"} /> Rip Pixels
              </Button>
              <Button disabled={this.props.html === "" || this.state.bulkUpload ? true : false} onClick={this.piggyback.bind(this)}>
                <Icon name={"code"} /> Piggyback
              </Button>
              <Button disabled={this.props.html === "" ? true : false} onClick={this.copyStringToClipboard.bind(this)}>
                <Icon name={"copy"} /> Copy
              </Button>

              <Button
                disabled={false}
                color={this.state.bulkUpload ? "olive" : "grey"}
                basic={this.state.bulkUpload ? false : true}
                onClick={this.changeBulkMode.bind(this)}>
                <Icon name={"clone"} /> Bulk Upload
              </Button>
            </Segment>
            <Segment compact padded={false} basic={true}>
              <Dropdown
                disabled={this.props.html === "" ? true : false}
                placeholder="Replace Macros"
                fluid={false}
                selection
                as={Button}
                clearable
                onChange={this.replaceMacro.bind(this)}
                options={this.state.replaceOptions}
              />
              {this.state.bulkUpload ? (
                <Button
                  color={"facebook"}
                  style={{ opacity: 1 }}
                  disabled={this.state.lp.length < 1 || this.props.html === "" || !this.state.bulkUpload ? true : false}>
                  <CSVLink
                    onClick={this.exportCSV.bind(this)}
                    style={{ textDecoration: "none", color: "white", opacity: "1 !important" }}
                    separator={","}
                    filename={this.state.csvtitle + ".csv"}
                    data={this.state.csvdata}>
                    Export DV360 Template
                  </CSVLink>
                </Button>
              ) : null}
              {this.state.bulkUpload ? (
                <Button disabled={false} color={!this.state.note ? "grey" : "blue"} onClick={this.setNote.bind(this)}>
                  <Icon name={!this.state.note ? "sticky note outline" : "sticky note"} /> Note
                </Button>
              ) : null}
              {this.state.bulkUpload ? (
                <Form.Field
                  placeholder={"Landing Page"}
                  value={this.state.lp}
                  onChange={this.changeLP.bind(this)}
                  control={Input}
                  type={"text"}
                  fluid={true}
                />
              ) : null}
            </Segment>
            <TextArea
              placeholder={this.state.bulkUpload ? "Paste tags from WCM TXT file" : "Paste tag"}
              className={"html-input"}
              value={this.props.html}
              onChange={this.changeTagText.bind(this)}
              rows={5}
            />
          </Grid.Column>
          <Grid.Column style={{ display: this.state.bulkUpload ? "none" : "block" }} tablet={8} computer={8} mobile={16}>
            <Segment compact padded={false} basic={true}>
              {" "}
              <Button
                color={this.state.iframestatus === "minimized" ? "red" : "black"}
                inverted={true}
                className={"button " + this.state.iframestatus}
                onClick={this.toggleIframeStatus.bind(this)}>
                <Icon name={"expand"} />
                {this.state.iframestatus === "minimized" ? "Fullscreen" : "Exit"}
              </Button>
              <Button onClick={this.refresh.bind(this)}>
                <Icon name={"refresh"} /> Refresh tag
              </Button>
            </Segment>
            <Segment compact padded={false} basic={true}>
              <Button style={{ opacity: 0 }}>#</Button>
            </Segment>

            {this.state.bulkUpload ? null : (
              <iframe frameBorder="0" scrolling="no" className={"html-output " + this.state.iframestatus} srcDoc={this.props.html}></iframe>
            )}
            {/* <div dangerouslySetInnerHTML={{__html: this.props.html}} /> */}
          </Grid.Column>
        </Grid.Row>
        <Modal open={this.state.modal}>
          <Modal.Header>Piggyback Pixel</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>Select type of piggyback</Header>
              <Form size={"mini"}>
                <Form.Group widths="equal">
                  <Form.Field
                    placeholder={"Tracker for impressions"}
                    value={this.state.imp}
                    onChange={this.setImpTracker.bind(this)}
                    control={Input}
                    label={"URL tracker for impressions"}
                    type={"text"}
                    fluid={true}
                  />
                  <Form.Field
                    width={2}
                    size={"mini"}
                    label={"Add"}
                    fluid={false}
                    disabled={this.state.imp.length < 5 || this.state.imp.indexOf("https://") < 0 ? true : false}
                    onClick={this.addImpTracker.bind(this)}
                    control={Button}
                    color={"olive"}>
                    <Icon name={"plus"} />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    placeholder={"Tracker for clicks"}
                    value={this.state.click}
                    onChange={this.setClickTracker.bind(this)}
                    control={Input}
                    label={"URL tracker for clicks"}
                    type={"text"}
                    fluid={true}
                  />
                  <Form.Field
                    width={2}
                    size={"mini"}
                    label={"Add"}
                    disabled={this.state.click.length < 5 || this.state.click.indexOf("https://") < 0 ? true : false}
                    fluid={false}
                    onClick={this.addClickTracker.bind(this)}
                    control={Button}
                    color={"olive"}>
                    <Icon name={"plus"} />
                  </Form.Field>
                </Form.Group>
                <Segment>
                  <Header size={"small"}>Impressions</Header>
                  {map(this.state.parallelImp, (value, index) => {
                    return (
                      <Form.Group label={"Impression trackers"} widths="equal" key={index}>
                        <Form.Field
                          width={12}
                          size={"mini"}
                          placeholder={"Tracker for impressions"}
                          control={Input}
                          readOnly={true}
                          value={value.replace(/\"/gm, "")}
                          type={"text"}
                          fluid={true}
                        />
                        <Form.Field
                          width={1}
                          color={"red"}
                          size={"mini"}
                          fluid={false}
                          onClick={this.removeImpTracker.bind(this, value)}
                          control={Button}>
                          <Icon name={"trash"} />
                        </Form.Field>
                      </Form.Group>
                    );
                  })}
                </Segment>
                <Segment>
                  <Header size={"small"}>Clicks</Header>
                  {map(this.state.parallelClick, (value, index) => {
                    return (
                      <Form.Group label={"Click trackers"} widths="equal" key={index}>
                        <Form.Field
                          width={12}
                          size={"mini"}
                          placeholder={"Tracker for clicks"}
                          control={Input}
                          readOnly={true}
                          value={value.replace(/\"/gm, "")}
                          type={"text"}
                          fluid={true}
                        />
                        <Form.Field
                          width={2}
                          color={"red"}
                          size={"mini"}
                          onClick={this.removeClickTracker.bind(this, value)}
                          fluid={false}
                          control={Button}>
                          <Icon name={"trash"} />
                        </Form.Field>
                      </Form.Group>
                    );
                  })}
                </Segment>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color={"orange"} onClick={this.clean.bind(this)}>
              <Icon name={"trash"} /> Clean
            </Button>
            <Button color={"grey"} onClick={this.closeModal.bind(this)}>
              <Icon name={"close"} /> Close
            </Button>
            <Button color={"facebook"} onClick={this.save.bind(this)}>
              <Icon name={"save"} /> Save
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    section: state.Reducer.App.section,
    html: state.Reducer.App.html,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHTML: (html) => {
      dispatch(setHTMLAction(html));
    },
    // getFormats: function() {
    //     dispatch(getFormatsAction(true));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagPreviewer);
