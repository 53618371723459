import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Menu } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import { setSectionAction } from "./Actions.js";
import { map } from "lodash";
import { SECTIONS } from "./Constants";
class MenuContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Menu className="Menu" borderless compact fixed={"top"} fluid={true} inverted={true}>
        <Menu.Item active={this.props.location.pathname === "/" || this.props.location.pathname === ""}>
          <NavLink activeClassName={"menu-link-active"} exact={true} to={"/"}>
            <Icon name={"home"} />
            {"Home"}
          </NavLink>
        </Menu.Item>
        <Menu.Menu position="right">
          {map(SECTIONS, (section, index) => {
            if (section.visible) {
              return (
                <Menu.Item
                  active={this.props.location.pathname.indexOf(section.path) >= 0 ? true : false}
                  key={index}
                  disabled={this.props.history.pathname === "formats" ? this.props.formatsDisabled : false}>
                  <NavLink
                    isActive={(match, location) => {
                      if (location.pathname.indexOf(section.path) >= 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    activeClassName={"menu-link-active"}
                    exact={true}
                    to={"/" + section.path}>
                    <Icon name={section.icon} />
                    {section.name}
                  </NavLink>
                </Menu.Item>
              );
            } else {
              return null;
            }
          })}
        </Menu.Menu>
      </Menu>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    formatsDisabled: state.Reducer.App.formatsDisabled,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSection: (section) => {
      dispatch(setSectionAction(section));
    },
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuContainer));
