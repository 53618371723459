export const VERSION = "3.0.1";

export const RULES = [
  { key: "load", value: "load", text: "On page load" },
  { key: "click", value: "click", text: "On click" },
  { key: "timer", value: "timer", text: "After (x) seconds" },
]

export const RICHMEDIA_FORMATS = [
  {
    key: "video_float", value: "video_float", text: "Video Float", setup: {
      "closeButton": {
        "showAtSecond": 0,
      },
      "mainDimensionsAndPosition": {
        "anchor": "",
        "width": "300px",
        "height": "250px"
      },
      "floatedDimensionsAndPosition": {
        "width": "150px",
        "height": "150px",
        "x": "left",
        "y": "bottom",
      },
      "floatedZIndex": {
        "zIndex": "10000"
      },
      "floatedMargins": {
        "marginLeft": "0px",
        "marginRight": "0px",
        "marginBottom": "0px",
        "marginTop": "0px",
      },
      "backgroundColor": {
        "fixed": "black",
        "floated": "black"
      },
      "videoAssets": {
        "mp4Src": "",
        "webmSrc": "",
        "poster": "",
      },
      "videoOptions": {
        "invertColorOfButtons": false,
        "metrics_for_20_seconds_maximum": false,
        "videoFitFixed": false,
        "videoFitFloated": false,
        "videoFullscreenButton": false,
        "loop": false,
        "autoPlay": true,
        "soundButton": true,
        "muted": true,
        "progressBar": true,
        "collapseAtVideoEnd": false
      },
      "trackers": {
        "adServerMacro": "%c",
        "impression": "",
        "click": "",
        "video_start": "",
        "video_2_seconds_view": "",
        "video_25": "",
        "video_50": "",
        "video_75": "",
        "video_100": ""
      }

    }
  },
  { key: "cube3d", value: "cube3d", text: "Cube Format", disabled: true },
  { key: "flip_book", value: "flip_book", text: "Flipbook", disabled: true },
  { key: "incontent", value: "incontent", text: "Video Incontent", disabled: true },
]

export const SECTIONS = [
  { name: "Formats", path: "formats", icon: "rocket", visible: true },
  { name: "Home", path: "", icon: "home", visible: false },
  { name: "IP Finder", path: "ipfinder", icon: "computer", visible: false },
  { name: "Feed Creator", path: "feed", icon: "feed", visible: true },
  { name: "Collect", path: "collect", icon: "angle double down", visible: true },
  { name: "DCO Tester", path: "dco", icon: "audio description", visible: true },
  { name: "Rich Media", path: "richmedia", icon: "star", visible: true },
  {
    name: "Tag Tester",
    path: "display-previewer",
    icon: "image",
    visible: true,
  },

  {
    name: "VAST Tester",
    path: "preroll-previewer",
    icon: "video",
    visible: false,
  },
];

export const API_SERVER_URL = process.env.NODE_ENV === "development" ? "http://localhost:8181" : window.location.origin;

export const GET_FORMATS = {
  url: API_SERVER_URL + "/api/formats",
  method: "GET",
};
export const GET_IPS = {
  url: API_SERVER_URL + "/api/checkip/",
  method: "GET",
};

export const SET_FEED = {
  url: API_SERVER_URL + "/api/feed",
  method: "POST",
};
export const UPLOAD_FEED = {
  url: API_SERVER_URL + "/api/upload/feed",
  method: "POST",
};
export const UPLOAD_RICHMEDIA = {
  url: API_SERVER_URL + "/api/upload/richmedia",
  method: "POST",
};
export const UPLOAD_RULE = {
  url: API_SERVER_URL + "/api/upload/rule",
  method: "POST",
};
export const GET_FEED = {
  url: API_SERVER_URL + "/api/feed",
  method: "GET",
};
export const DELETE_FORMATS = {
  url: API_SERVER_URL + "/api/formats",
  method: "DELETE",
};
export const SAVE_FORMATS = {
  url: API_SERVER_URL + "/api/formats",
  method: "POST",
};
export const CREATE_FORMATS = {
  url: API_SERVER_URL + "/api/formats",
  method: "PUT",
};

export const GET_NEWS = { url: API_SERVER_URL + "/api/news", method: "GET" };
export const DELETE_NEWS = {
  url: API_SERVER_URL + "/api/news",
  method: "DELETE",
};
export const SAVE_NEWS = { url: API_SERVER_URL + "/api/news", method: "POST" };
export const CREATE_NEWS = { url: API_SERVER_URL + "/api/news", method: "PUT" };

export const PUBLISHERS = [
  { net: "All", value: "All", text: "All Publishers" },
  { net: "Cofina", value: "A Bola", text: "A Bola" },
  { net: "Cofina", value: "Record", text: "Record" },
  { net: "Cofina", value: "Sábado", text: "Sábado" },
  { net: "Cofina", value: "Correio da Manha", text: "Correio da Manha" },
  { net: "Cofina", value: "Negocios", text: "Negocios" },
  { net: "Cofina", value: "Flash", text: "Flash" },
  { net: "Cofina", value: "Vidas", text: "Vidas" },
  { net: "Cofina", value: "Máxima", text: "Máxima" },
  { net: "Cofina", value: "Aquela Máquina", text: "Aquela Máquina" },
  {
    net: "Global Media",
    value: "Diario de Noticias",
    text: "Diario de Noticias",
  },
  {
    net: "Global Media",
    value: "Jornal de Noticias",
    text: "Jornal de Noticias",
  },
  { net: "Global Media", value: "TSF", text: "TSF" },
  { net: "Global Media", value: "Dinheiro Vivo", text: "Dinheiro Vivo" },
  { net: "Global Media", value: "V Digital", text: "V Digital" },
  { net: "Global Media", value: "O Jogo", text: "O Jogo" },
  { net: "Global Media", value: "Motor 24", text: "Motor 24" },
  { net: "Global Media", value: "Evasoes", text: "Evasoes" },
  { net: "Global Media", value: "Volta ao mundo", text: "Volta ao mundo" },
  {
    net: "Global Media",
    value: "Noticias Magazine",
    text: "Noticias Magazine",
  },
  { net: "Global Media", value: "N-TV", text: "N-TV" },
  { net: "Global Media", value: "Delas", text: "Delas" },
  { net: "Global Media", value: "MensHealth", text: "MensHealth" },
  { net: "Global Media", value: "WomansHealth", text: "WomansHealth" },
  { net: "iOL", value: "MaisFutebol", text: "MaisFutebol" },
  { net: "iOL", value: "TVI", text: "TVI" },
  { net: "iOL", value: "TVI24", text: "TVI24" },
  { net: "iOL", value: "Selfie", text: "Selfie" },
  { net: "iOL", value: "NIT", text: "NIT" },
  { net: "iOL", value: "Lux", text: "Lux" },
  { net: "iOL", value: "TVI Player", text: "TVI Player" },
  { net: "iOL", value: "Auto-Portal", text: "Auto-Portal" },
  { net: "Impresa", value: "Expresso", text: "Expresso" },
  { net: "Impresa", value: "Tribuna", text: "Tribuna" },
  { net: "Impresa", value: "Blitz", text: "Blitz" },
  { net: "Impresa", value: "Visão", text: "Visão" },
  { net: "Impresa", value: "Vida Extra", text: "Vida Extra" },
  { net: "Impresa", value: "Boa cama Boa mesa", text: "Boa cama Boa mesa" },
  { net: "Impresa", value: "SIC", text: "SIC" },
  { net: "Impresa", value: "SIC Noticias", text: "SIC Noticias" },
  { net: "Impresa", value: "Volante", text: "Volante" },
  { net: "Impresa", value: "Fama show", text: "Fama show" },
  { net: "Impresa", value: "SIC Caras", text: "SIC Caras" },
  { net: "Impresa", value: "SIC Mulher", text: "SIC Mulher" },
  { net: "Impresa", value: "SIC Radical", text: "SIC Radical" },
  { net: "Observador", value: "Observador", text: "Observador" },
  { net: "Observador", value: "MAG", text: "MAG" },
  { net: "Sonae", value: "Público", text: "Público" },
  { net: "RTP", value: "RTP", text: "RTP" },
  { net: "Microsoft", value: "MSN", text: "MSN" },
  { net: "MotorPress", value: "Auto-Hoje", text: "Auto-Hoje" },
  { net: "SAPO", value: "Turbo", text: "Turbo" },
  { net: "SAPO", value: "Sapo", text: "Sapo" },
  { net: "SAPO", value: "Sapo 24", text: "Sapo 24" },
  { net: "SAPO", value: "Activa", text: "Activa" },
  { net: "SAPO", value: "Caras", text: "Caras" },
  { net: "SAPO", value: "Exame Informática", text: "Exame Informática" },
  { net: "SAPO", value: "Auto Monitor", text: "Auto Monitor" },
  { net: "SAPO", value: "Jornal Económico", text: "Jornal Económico" },
  { net: "SAPO", value: "Jornal i", text: "Jornal i" },
  { net: "SAPO", value: "Sol", text: "Sol" },
  { net: "BlognJoy", value: "Razao Automovel", text: "Razão Automovel" },
];

export const STANDARD_FORMATS = [
  { key: "banner", width: 320, height: 50, label: "Banner", text: "320x50", value: "320x50" },
  { key: "intro_mobile", width: 320, height: 480, label: "Intro Mobile", text: "320x480", value: "320x480" },
  { key: "mrec", width: 300, height: 250, label: "Medium Rectangle", text: "300x250", value: "300x250" },
  { key: "leaderboard", width: 728, height: 90, label: "Leaderboard", text: "728x90", value: "728x90" },
  { key: "skyscrapper", width: 120, height: 600, label: "Skyscrapper", text: "120x600", value: "120x600" },
  { key: "wideskyscrapper", width: 160, height: 600, label: "Wide Skyscrapper", text: "160x600", value: "160x600" },
  { key: "lrec", width: 333, height: 280, label: "Wide Rectangle", text: "333x280", value: "333x280" },
  { key: "square", width: 250, height: 250, label: "Square", text: "250x250", value: "250x250" },
  { key: "billboard", width: 970, height: 250, label: "Billboard", text: "970x250", value: "970x250" },
  { key: "halfpage", width: 300, height: 600, label: "Halfpage", text: "300x600", value: "300x600" }
]

export const AD_SERVERS_TAGS = {
  DBM: {
    name: "DV360",
    CB: "${CACHEBUSTER}",
    CLICK_ENC: "${CLICK_URL}",
    CLICK: "${CLICK_URL}",
    ENC: true,
  },
  DFA: {
    name: "DFA",
    CB: "%n",
    CLICK: "%c",
    ENC: false,
  },
  DFP: {
    name: "DFP",
    CB: "%%CACHEBUSTER%%",
    CLICK: "%%CLICK_URL_UNESC%%",
    CLICK_ENC: "%%CLICK_URL_ESC_ESC%%",
    ENC: false,
  },
  ADFORM: {
    name: "AdForm",
    CB: "%%ADFRND%%",
    CLICK: "%%c1;cpdir=",
    CLICK_ENC: "%%c1;cpdir=",
    ENC: false,
  },
  "SMART ADSERVER": {
    name: "Smart Ad-Server",
    CB: "[timestamp]",
    CLICK: "[countgo] ",
    CLICK_ENC: "[countgoEncoded]",
    ENC: true,
  },
  APPNEXUS: {
    name: "Xandr",
    CB: "${CACHEBUSTER}",
    CLICK: "${CLICK_URL}",
    CLICK_ENC: "${CLICK_URL}",
    ENC: true,
  },
  MEDIAMATH: {
    name: "MediaMath",
    CB: "[RANDOM_NUMBER]",
    CLICK: "[UNENCODED_CLICK_REDIRECT]",
    CLICK_ENC: "[ENCODED_CLICK_REDIRECT]",
    ENC: false,
  },
  SMADEX: {
    name: "Smadex",
    CB: "{$timestamp$}",
    CLICK: "{$CLICK_TRACK_URL$}",
    CLICK_ENC: "${CLICK_URL}",
    ENC: true,
  },
  OPENX: {
    name: "Open X",
    CB: "{random}",
    CLICK: "{clickurl_enc}",
    CLICK_ENC: "{clickurl_enc}",
    ENC: true,
  },
  "OPEN ADSTREAM": {
    name: "Open AdStream",
    CB: "%%REALRAND%%",
    CLICK: "%%C%%?",
    CLICK_ENC: "%%C%%?",
    ENC: false,
  },
  TURN: {
    name: "Turn",
    CB: "$!{AD_CALL_ID} ",
    CLICK: "XXCLICK_FORM_URL[]XX",
    CLICK_ENC: "XXCLICK_FORM_URL[]XX",
    ENC: false,
  },
};

export const PLATFORMS = [
  { value: "Vanilla HTML5", text: "Vanilla HTML5" },
  { value: "Adobe Animate", text: "Adobe Animate" },
  { value: "Adobe Edge", text: "Adobe Edge" },
  { value: "Google Web Designer", text: "Google Web Designer" },
];
