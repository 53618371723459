import React, { Component } from 'react';
import { connect } from "react-redux";
import { Grid, } from "semantic-ui-react";

class NotFound extends Component {
  render() {
    return (
      <Grid className="NotFound">
        <Grid.Row>
          <Grid.Column>
            Page was not found!
                </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>

          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    section: state.Reducer.App.section
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    // getFormats: function() {
    //     dispatch(getFormatsAction(true));
    // },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
