import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Grid, Button, Icon, Modal, Header, Dropdown, TextArea, Container } from "semantic-ui-react";
import { cloneDeep, map, get, find, isNil, each, uniq, set } from "lodash";
import uuid from "uuid";
import XLSX from "xlsx";
import moment from "moment";
import { setWAMIDAction, request, setIDOfRulesAction, setLabelAction, saveBoardRulesAction, deleteBoardRuleAction, addBoardRuleAction, setBoardRulesAction } from "./Actions.js";
import { UPLOAD_RULE, RULES } from "./Constants.js";
class BoardTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      proceedId: null,
      wamIDError: false,
      canLoadRules: false,
      boardLabelError: false,
      cookieDuration: 30,
      cookieDurationError: false,
      ruleNameError: false,
      dropIframe: false,
      sectionError: false,
      triggerTypeError: false,
      showSaveModal: false,
      externalData: [],
      isExact: false,
      cookieValue: "",
      cookieValueError: false,
      dropCookie: false,
      cookieKey: "",
      cookieKeyError: false,
      ruleName: "",
      triggerType: null,
      selector: "",
      element_attribute_name: "",
      element_attribute_value: "",
      selector_wbo_id: "",
      tagModal: false,
      cdnStatusModal: false,
      tagExport: "",
      cdnStatus: false,
      section: "",
      saved: true,
      seconds: "",
      url: "",
      id: null,
      overwriteModal: false,
    };
  }
  resetRule() {
    this.setState({
      id: null,
      ruleName: "",
      dropCookie: false,
      isExact: false,
      dropIframe: false,
      cookieValue: "",
      cookieKey: "",
      cookieDuration: 30,
      triggerType: null,
      selector: "",
      element_attribute_name: "",
      element_attribute_value: "",
      selector_wbo_id: "",
      seconds: "",
      section: "",
      url: "",
      saved: false,
    });
  }
  saveRules() {
    if (this.validate()) {
      var rules = cloneDeep(this.props.rules);
      var rule = find(rules, { id: this.state.id });
      console.log(rule, this.state.id);
      set(rule, "name", this.state.ruleName);
      set(rule, "event", this.state.triggerType);
      set(rule, "cookie", this.state.dropCookie);
      set(rule, "is_exact", this.state.isExact);
      set(rule, "cookie_value", this.state.cookieValue);
      set(rule, "cookie_key", this.state.cookieKey);
      set(rule, "drop_iframe", this.state.dropIframe);
      set(rule, "cookie_duration", this.state.cookieDuration);
      set(rule, "selector", this.state.selector);
      set(rule, "element_attribute_name", this.state.element_attribute_name);
      set(rule, "element_attribute_value", this.state.element_attribute_value);
      set(rule, "selector_wbo_id", this.state.selector_wbo_id);
      set(rule, "seconds", this.state.seconds);
      set(rule, "section", this.state.section);
      set(rule, "url", this.state.url);
      this.setState({ saved: true, showSaveModal: false });
      this.props.saveBoardRules(rules);
    }
  }
  setWAMID(e, data) {
    this.props.setWAMID(data.value);
    this.props.setIDOfRules(data.value);
    setTimeout(() => {
      this.checkAnyExistantRule();
    }, 0);
  }

  setLabel(e, data) {
    var label = data.value.replace(/  +/g, " ");
    this.props.setLabel(label);
    this.setState({ saved: false });
  }

  toggleCDNStatusModal() {
    this.setState({ cdnStatusModal: !this.state.cdnStatusModal });
  }

  exportExcel() {
    var nameFile = " ";
    if (this.props.wamID) {
      nameFile = " - " + this.props.wamID + " - ";
    } else {
      nameFile = " ";
    }

    var wb = XLSX.utils.book_new();
    var ws = null;
    var ws_data = [];
    //INTRO PAGE
    var ws_name = "Rules " + this.props.wamID;
    ws_data.push(["rule_name", "full_url", "is_exact", "trigger", "load_delay", "click_selector", "element_attribute_name", "element_attribute_value", "wbo_id", "piggyback", "cookie", "cookie_key", "cookie_value", "cookie_duration"]);
    each(this.props.rules, (rule) => {
      ws_data.push([get(rule, "name", ""), get(rule, "section", ""), get(rule, "is_exact", false) === false ? "false" : "true", get(rule, "event", ""), get(rule, "seconds", ""), get(rule, "selector", ""), get(rule, "element_attribute_name", ""), get(rule, "element_attribute_value", ""), get(rule, "selector_wbo_id", ""), get(rule, "url", ""), get(rule, "cookie", "").toString(), get(rule, "cookie_key", ""), get(rule, "cookie_value", ""), get(rule, "cookie_duration", "")]);
    });
    ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name.substring(0, 31));
    XLSX.writeFile(wb, "Rules" + nameFile + moment().format("YYYY-MM-DD") + ".xlsx");
  }

  changeRule(e, data) {
    var rules = cloneDeep(this.props.rules);
    var rule = find(rules, { id: data.value });
    this.setState({
      id: get(rule, "id", null),
      ruleName: get(rule, "name", ""),
      dropCookie: get(rule, "cookie", false),
      isExact: get(rule, "is_exact", false),
      cookieValue: get(rule, "cookie_value", ""),
      cookieKey: get(rule, "cookie_key", ""),
      cookieDuration: get(rule, "cookie_duration", 30),
      dropIframe: get(rule, "drop_iframe", false),
      triggerType: get(rule, "event", ""),
      seconds: get(rule, "seconds", ""),
      selector: get(rule, "selector", ""),
      element_attribute_name: get(rule, "element_attribute_name", ""),
      element_attribute_value: get(rule, "element_attribute_value", ""),
      selector_wbo_id: get(rule, "selector_wbo_id", ""),
      section: get(rule, "section", ""),
      url: get(rule, "url", ""),
      saved: true,
    });
  }
  completeRule(e, data) {
    console.log("complete rule", data.value);
    if (this.state.saved) {
      var rules = cloneDeep(this.props.rules);
      var rule = null;
      if (data.value) {
        rule = find(rules, { id: data.value });
      } else {
        rule = rules[0];
      }
      this.setState({
        id: get(rule, "id", null),
        ruleName: get(rule, "name", ""),
        dropCookie: get(rule, "cookie", false),
        isExact: get(rule, "is_exact", false),
        cookieValue: get(rule, "cookie_value", ""),
        dropIframe: get(rule, "drop_iframe", false),
        cookieKey: get(rule, "cookie_key", ""),
        cookieDuration: get(rule, "cookie_duration", 30),
        triggerType: get(rule, "event", ""),
        seconds: get(rule, "seconds", ""),
        selector: get(rule, "selector", ""),
        selector_wbo_id: get(rule, "selector_wbo_id", ""),
        element_attribute_value: get(rule, "element_attribute_value", ""),
        element_attribute_name: get(rule, "element_attribute_name", ""),
        section: get(rule, "section", ""),
        url: get(rule, "url", ""),
        saved: true,
      });
    } else {
      this.setState({ showSaveModal: true, proceedId: data.value });
    }
  }
  getTag() {
    var txt = "BOARD TAG AND IMPLEMENTATION RULES\n\nIMPLEMENTATION:\n\n1: COPY the tag below:\n" + "<script async='true' type='text/javascript' src='https://cloud.weborama.design/board.js?wam_id=" + this.props.wamID + "&wam_label=" + this.props.boardLabel + "'></script>\n\n2: a) PASTE the tag in the HTML (<body>) or insert it on a container (like Google Tag Manager) inside of the following URL's:\n\n";

    var s = [];
    each(this.props.rules, (rule) => {
      s.push(rule.section);
    });
    s = uniq(s);
    each(s, (section) => {
      txt += "- " + section + "\n";
    });
    txt = txt + "\nOR\n\n2: b) Add the tag to be triggered at the following domains (including all sub-pages of the domains):\n\n";
    txt += "\n\nSIDE NOTES:\n - The tag above can be implemented on a Google Tag Manager or other tag container, can also be implemented directly onto the HTML of the site.\n - It's recommended to have a CMP (on the client site). This tag is already compliant to TCFv2.\n - Debug the tag on the client site by adding '?wbo=true' as parameter. Ex: https://ikea.pt/example/product.html?wbo=true.";

    var file = new Blob([txt], { type: "text/plain" });
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = "board-tag-and-implementation-rules_" + this.props.wamID + "_" + moment().format("YYYY-MM-DD") + ".txt";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
  downloadTemplate() {
    window.open("https://ams3.digitaloceanspaces.com/weborama/rules_template.xlsx", "_blank");
  }
  importRules() {
    var newRules = [];
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: "binary",
      });
      var EXCEL_DATA = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
      each(EXCEL_DATA, (row, index) => {
        console.log(row);
        var rule_obj = {};
        if (get(row, "cookie", "false") === "true") {
          set(rule_obj, "cookie", true);
          set(rule_obj, "cookie_key", get(row, "cookie_key", ""));
          set(rule_obj, "cookie_duration", parseInt(get(row, "cookie_duration", 30)));
          set(rule_obj, "cookie_value", get(row, "cookie_value", ""));
        } else {
          set(rule_obj, "cookie", false);
          set(rule_obj, "cookie_key", get(row, "cookie_key", ""));
          set(rule_obj, "cookie_duration", parseInt(get(row, "cookie_duration", 30)));
          set(rule_obj, "cookie_value", get(row, "cookie_value", ""));
        }
        if (get(row, "trigger", "").length > 0) {
          set(rule_obj, "event", get(row, "trigger", ""));
        } else {
          set(rule_obj, "event", "load");
        }
        if (get(row, "rule_name", "").length > 0) {
          set(rule_obj, "name", get(row, "rule_name", ""));
        } else {
          set(rule_obj, "name", "undefined");
        }
        if (get(row, "full_url", "").length > 0) {
          set(rule_obj, "section", get(row, "full_url", ""));
        } else {
          set(rule_obj, "section", "");
        }
        if (get(row, "is_exact", "").length > 0) {
          set(rule_obj, "is_exact", get(row, "is_exact", "false") === "false" ? false : true);
        } else {
          set(rule_obj, "is_exact", false);
        }
        if (get(row, "click_selector", "").length > 0) {
          set(rule_obj, "selector", get(row, "click_selector", ""));
        } else {
          set(rule_obj, "selector", "");
        }
        if (get(row, "element_attribute_name", "").length > 0) {
          set(rule_obj, "element_attribute_name", get(row, "element_attribute_name", ""));
        } else {
          set(rule_obj, "element_attribute_name", "");
        }
        if (get(row, "element_attribute_value", "").length > 0) {
          set(rule_obj, "element_attribute_value", get(row, "element_attribute_value", ""));
        } else {
          set(rule_obj, "element_attribute_value", "");
        }
        if (get(row, "wbo_id", "").toString().length > 0) {
          set(rule_obj, "selector_wbo_id", get(row, "wbo_id", "").toString());
        } else {
          set(rule_obj, "selector_wbo_id", "");
        }
        if (get(row, "load_delay") > 0) {
          set(rule_obj, "seconds", get(row, "load_delay", 0));
        } else {
          set(rule_obj, "seconds", 0);
        }
        if (get(row, "piggyback", "").length > 0) {
          set(rule_obj, "url", get(row, "piggyback", ""));
        } else {
          set(rule_obj, "url", "");
        }
        set(rule_obj, "id", uuid.v4());
        newRules.push(rule_obj);
      });
      this.props.setBoardRules(newRules);
      document.querySelector("#excel-input").value = null;
    };
    reader.onerror = (ex) => {
      console.log(ex);
    };
    reader.readAsBinaryString(document.querySelector("#excel-input").files[0]);
  }
  addRule() {
    if (this.validate()) {
      var rules = cloneDeep(this.props.rules);
      var ruleObj = {
        id: uuid.v4(),
        name: this.state.ruleName + " - New Rule - " + moment().format("YY:MM:DD HH:mm:ss"),
        event: this.state.triggerType,
        cookie: this.state.dropCookie,
        is_exact: this.state.isExact,
        cookie_key: this.state.cookieKey,
        drop_iframe: this.state.dropIframe,
        cookie_value: this.state.cookieValue,
        cookie_duration: this.state.cookieDuration,
        selector: this.state.selector,
        selector_wbo_id: this.state.selector_wbo_id,
        seconds: this.state.seconds,
        section: this.state.section,
        url: this.state.url,
      };
      rules.push(ruleObj);
      this.props.addBoardRule(rules);
      this.setState({
        id: get(ruleObj, "id", null),
        ruleName: get(ruleObj, "name", ""),
        isExact: get(ruleObj, "is_exact", false),
        dropCookie: get(ruleObj, "cookie", false),
        cookieValue: get(ruleObj, "cookie_value", ""),
        cookieKey: get(ruleObj, "cookie_key", ""),
        cookieDuration: get(ruleObj, "cookie_duration", 30),
        dropIframe: get(ruleObj, "drop_iframe", false),
        triggerType: get(ruleObj, "event", ""),
        seconds: get(ruleObj, "seconds", ""),
        selector: get(ruleObj, "selector", ""),
        element_attribute_name: get(ruleObj, "element_attribute_name", ""),
        element_attribute_value: get(ruleObj, "element_attribute_value", ""),
        selector_wbo_id: get(ruleObj, "selector_wbo_id", ""),
        section: get(ruleObj, "section", ""),
        url: get(ruleObj, "url", ""),
        saved: false,
      });
    }
  }

  deleteRule(e) {
    var r = [];
    each(this.props.rules, (rule) => {
      if (rule.id !== this.state.id) {
        r.push(rule);
      }
    });
    console.log(r);
    this.setState({ saved: true });
    this.resetRule();
    this.props.deleteBoardRule(r);
  }
  checkAnyExistantRule() {
    fetch("https://ams3.digitaloceanspaces.com/weborama/rules/" + this.props.wamID + ".json")
      .then((response) => {
        if (response.status >= 400) {
          this.setState({ canLoadRules: false });
        } else {
          this.setState({ canLoadRules: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  loadRulesData() {
    fetch("https://ams3.digitaloceanspaces.com/weborama/rules/" + this.props.wamID + ".json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          this.setState({ overwriteModal: true, externalData: get(data, "rules", []) });
          this.props.setLabel(get(data, "label", ""));
        } else {
          this.setState({ overwriteModal: false });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  closeOverwriteModal() {
    this.setState({ overwriteModal: false });
  }
  closeTagModal() {
    this.setState({ tagModal: false });
  }
  closeSaveModal(e) {
    var rules = cloneDeep(this.props.rules);
    var rule = find(rules, { id: this.state.proceedId });
    if (rule) {
      this.setState({
        id: get(rule, "id", null),
        ruleName: get(rule, "name", ""),
        dropCookie: get(rule, "cookie", false),
        isExact: get(rule, "is_exact", false),
        cookieValue: get(rule, "cookie_value", ""),
        cookieKey: get(rule, "cookie_key", ""),
        dropIframe: get(rule, "drop_iframe", false),
        cookieDuration: get(rule, "cookie_duration", 30),
        triggerType: get(rule, "event", ""),
        seconds: get(rule, "seconds", ""),
        selector: get(rule, "selector", ""),
        selector_wbo_id: get(rule, "selector_wbo_id", ""),
        section: get(rule, "section", ""),
        url: get(rule, "url", ""),
        saved: true,
        showSaveModal: false,
      });
    }
  }
  setRuleFromExternalData() {
    this.setState({
      id: get(this.state.externalData[0], "id", null),
      ruleName: get(this.state.externalData[0], "name", ""),
      dropCookie: get(this.state.externalData[0], "cookie", false),
      isExact: get(this.state.externalData[0], "is_exact", false),
      cookieValue: get(this.state.externalData[0], "cookie_value", ""),
      cookieKey: get(this.state.externalData[0], "cookie_key", ""),
      dropIframe: get(this.state.externalData[0], "drop_iframe", false),
      cookieDuration: get(this.state.externalData[0], "cookie_duration", 30),
      triggerType: get(this.state.externalData[0], "event", ""),
      seconds: get(this.state.externalData[0], "seconds", ""),
      selector: get(this.state.externalData[0], "selector", ""),
      selector_wbo_id: get(this.state.externalData[0], "selector_wbo_id", ""),
      section: get(this.state.externalData[0], "section", ""),
      url: get(this.state.externalData[0], "url", ""),
      saved: true,
      overwriteModal: false,
    });
    this.props.setBoardRules(this.state.externalData);
  }
  validate() {
    var flag = true;
    if (this.props.wamID.length < 1) {
      flag = false;
      this.setState({ wamIDError: true });
    } else {
      this.setState({ wamIDError: false });
    }

    if (this.state.ruleName.length < 1) {
      this.setState({ ruleNameError: true });
      flag = false;
    } else {
      this.setState({ ruleNameError: false });
    }

    if (this.state.section.length < 1) {
      this.setState({ sectionError: true });
      flag = false;
    } else {
      this.setState({ sectionError: false });
    }

    if (isNil(this.state.triggerType)) {
      this.setState({ triggerTypeError: true });
      flag = false;
    } else {
      this.setState({ triggerTypeError: false });
    }
    if (this.state.dropCookie) {
      if (this.state.cookieValue.length < 1) {
        this.setState({ cookieValueError: true });
        flag = false;
      } else {
        this.setState({ cookieValueError: false });
      }
      if (this.state.cookieKey.length < 1) {
        this.setState({ cookieKeyError: true });
        flag = false;
      } else {
        this.setState({ cookieKeyError: false });
      }
    }
    return flag;
  }
  backupCurrentRules() {
    var file = new Blob([JSON.stringify(this.state.externalData)], { type: "application/json" });
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = "board-rules_" + this.props.wamID + "_" + moment().format("YYYY-MM-DD") + ".json";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  toggleTag() {
    this.setState({
      tagModal: !this.state.tagModal,
      tagExport: "<script async='true' type='text/javascript' src='https://cloud.weborama.design/board.js?wam_id=" + this.props.wamID + "&wam_label=" + this.props.boardLabel + "'></script>",
    });
  }
  uploadRule() {
    if (this.validate()) {
      this.setState({ loading: true });
      this.props.uploadDataRule(this.props.rules, this.props.boardLabel, this.props.wamID, (response) => {
        this.setState({ loading: false, saved: true });
        if (!isNil(response)) {
          if (isNil(get(response, "error", null))) {
            this.setState({
              cdnStatusModal: true,
              cdnStatus: "Rules uploaded with success to CDN.",
            });
          } else {
            this.setState({ cdnStatusModal: true, cdnStatus: "Error uploading rules to CDN, contact support-pt@weborama.com" });
          }
        } else {
        }
      });
    } else {
      console.log("error");
    }
  }
  render() {
    return (
      <Grid className="BoardTag" padded>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Input fluid={true} width={2} error={this.state.wamIDError} type="number" label="WAM ID" value={this.props.wamID} onChange={this.setWAMID.bind(this)} placeholder="WAM ID" />
                <Form.Button fluid={true} disabled={!this.state.canLoadRules} width={2} color={"blue"} icon={"cloud download"} label="Load Rules" onClick={this.loadRulesData.bind(this)} />
                <Form.Input fluid={true} width={2} label="Client Label *" error={this.state.boardLabelError} maxLength={7} value={this.props.boardLabel.replace(/\s/g, "").toUpperCase()} onChange={this.setLabel.bind(this)} placeholder="Example: IKEA, VW, COF, " />

                <Form.Button
                  content={
                    <label htmlFor="excel-input" className={"upload-field full"}>
                      Excel
                    </label>
                  }
                  className={"button-input-excel nopad-nomargin"}
                  width={2}
                  label={"Import"}
                  fluid={true}
                  color={"facebook"}></Form.Button>
                <Form.Button width={2} label={"Export"} color={"olive"} onClick={this.exportExcel.bind(this)} fluid={true}>
                  Excel
                  <Icon name="arrow right" />
                </Form.Button>
                <Form.Button width={2} disabled={this.props.wamID ? false : true} label={"Script"} color={"grey"} onClick={this.toggleTag.bind(this)} fluid={true}>
                  <Icon name="code" />
                </Form.Button>
                <Form.Field
                  width={4}
                  control={Dropdown}
                  disabled={this.props.rules.length < 1 ? true : false}
                  options={map(this.props.rules, (rule) => {
                    return { key: rule.id, text: rule.name, value: rule.id };
                  })}
                  fluid={true}
                  label={"Rules (" + this.props.rules.length + " rules)"}
                  value={this.state.id}
                  clearable
                  search
                  onChange={this.changeRule.bind(this)}
                  selection
                />
              </Form.Group>
            </Form>
            <input type="file" ref={this.excelInput} encType="multipart/form-data" id="excel-input" className="hidden-inputs" onChange={this.importRules.bind(this)} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Input
                  width={4}
                  fluid={true}
                  label="Rule Name"
                  error={this.state.ruleNameError}
                  value={this.state.ruleName}
                  onChange={(e, data) => {
                    this.setState({ ruleName: data.value, saved: false });
                  }}
                  placeholder="Ex: Visitor of product 123"
                />

                <Form.Input
                  width={6}
                  fluid={true}
                  label={"Rule will be triggered at:" + this.state.ruleName + ""}
                  value={this.state.section}
                  error={this.state.sectionError}
                  onChange={(e, data) => {
                    this.setState({ section: data.value, saved: false });
                  }}
                  placeholder="Ex: https://www.ikea.pt/tables/table.html"
                />
                <Form.Button
                  width={2}
                  fluid={true}
                  label={"Exact Path"}
                  color={this.state.isExact ? "blue" : "orange"}
                  icon={this.state.isExact ? "check circle" : "remove circle"}
                  content={this.state.isExact ? "Yes" : "No"}
                  onClick={(e, data) => {
                    this.setState({ isExact: !this.state.isExact, saved: false });
                  }}
                  placeholder="Ex: https://www.ikea.pt/tables/table.html"
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Dropdown}
                  options={RULES}
                  fluid={true}
                  width={4}
                  label="Trigger Type"
                  clearable
                  value={this.state.triggerType}
                  error={this.state.triggerTypeError}
                  onChange={(e, data) => {
                    this.setState({ triggerType: data.value, saved: false });
                  }}
                  selection
                />
                {this.state.triggerType === "timer" ? (
                  <Form.Input
                    fluid={true}
                    width={4}
                    type="number"
                    label="Seconds"
                    value={this.state.seconds}
                    onChange={(e, data) => {
                      this.setState({ seconds: parseInt(data.value), saved: false });
                    }}
                    placeholder="Ex: 10"
                  />
                ) : null}
                {this.state.triggerType === "click" ? (
                  <Form.Input
                    fluid={true}
                    width={2}
                    label="Selector (class)"
                    value={this.state.selector}
                    onChange={(e, data) => {
                      this.setState({ selector: data.value, saved: false });
                    }}
                    placeholder="Ex: #submitButton"
                  />
                ) : null}
                {this.state.triggerType === "click" ? (
                  <Form.Input
                    fluid={true}
                    type={"number"}
                    width={2}
                    label="WBO-ID (beta)"
                    value={this.state.selector_wbo_id}
                    onChange={(e, data) => {
                      this.setState({ selector_wbo_id: data.value, saved: false });
                    }}
                    placeholder="Ex: 2"
                  />
                ) : null}
                {this.state.triggerType === "click" ? (
                  <Form.Input
                    fluid={true}
                    width={2}
                    label="Attribute Name"
                    value={this.state.element_attribute_name}
                    onChange={(e, data) => {
                      this.setState({ element_attribute_name: data.value, saved: false });
                    }}
                    placeholder="Ex: 2"
                  />
                ) : null}
                {this.state.triggerType === "click" ? (
                  <Form.Input
                    fluid={true}
                    width={2}
                    label="Attribute Value"
                    value={this.state.element_attribute_value}
                    onChange={(e, data) => {
                      this.setState({ element_attribute_value: data.value, saved: false });
                    }}
                    placeholder="Ex: 2"
                  />
                ) : null}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Input
                  width={10}
                  label="Piggyback (URL only) *"
                  value={this.state.url}
                  onChange={(e, data) => {
                    this.setState({ url: data.value, saved: false });
                  }}
                  placeholder="Ex: URL to trigger"
                />
                <Form.Button
                  width={2}
                  fluid={true}
                  label="Trigger"
                  content={this.state.dropIframe ? "1st Party Iframe" : "Pixel"}
                  icon={this.state.dropIframe ? "download" : "check"}
                  color={this.state.dropIframe ? "blue" : "orange"}
                  onClick={(e, data) => {
                    this.setState({ dropIframe: !this.state.dropIframe, saved: false });
                  }}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Field
                  width={2}
                  fluid={true}
                  control={Button}
                  label={"Drop cookie *"}
                  icon={!this.state.dropCookie ? "stop circle outline" : "check circle"}
                  color={!this.state.dropCookie ? "orange" : "olive"}
                  error={this.state.dropCookieError}
                  onClick={(e, data) => {
                    this.setState({ dropCookie: !this.state.dropCookie, saved: false });
                  }}
                />
                <Form.Input
                  width={2}
                  label="Key"
                  disabled={!this.state.dropCookie}
                  value={this.state.cookieKey}
                  error={this.state.cookieKeyError}
                  onChange={(e, data) => {
                    this.setState({ cookieKey: data.value, saved: false });
                  }}
                  placeholder="Ex:color"
                />
                <Form.Input
                  width={2}
                  label="Value"
                  disabled={!this.state.dropCookie}
                  value={this.state.cookieValue}
                  error={this.state.cookieValueError}
                  onChange={(e, data) => {
                    this.setState({ cookieValue: data.value, saved: false });
                  }}
                  placeholder="Ex: blue"
                />
                <Form.Input
                  width={2}
                  fluid={true}
                  disabled={!this.state.dropCookie}
                  type={"number"}
                  max={60}
                  min={1}
                  label="Duration (days)"
                  value={this.state.cookieDuration}
                  onChange={(e, data) => {
                    this.setState({ cookieDuration: data.value < 1 ? 1 : data.value, saved: false });
                  }}
                  placeholder="Ex: 30"
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column tablet={8} computer={8} mobile={16}>
            <Form>
              <Form.Group widths="equal">
                <Form.Field width={2} fluid={true} control={Button} icon={"plus"} label={"Add Rule"} onClick={this.addRule.bind(this)} color="facebook" />
                <Form.Field width={2} fluid={true} control={Button} icon={"trash"} label={"Delete Rule"} onClick={this.deleteRule.bind(this)} color="red" />
                <Form.Field width={2} fluid={true} control={Button} icon={"save"} disabled={this.state.saved} label={"Save Rule"} onClick={this.saveRules.bind(this)} color="olive" />

                <Form.Field width={2} fluid={true} disabled={!this.state.saved} control={Button} icon={"cloud upload"} loading={this.state.loading} label={"Upload Rules"} onClick={this.uploadRule.bind(this)} color="blue" />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            {this.state.triggerType ? <Header content={"Summary:"} /> : null}
            <Form className="sumup">
              {this.state.wamID ? (
                <div>
                  The tag will save all data on WAM account <b>{this.props.wamID}</b>
                </div>
              ) : null}
              {this.state.section ? (
                <div>
                  The rule in <b className="highlight">'{this.state.ruleName}'</b> will be triggered {this.state.isExact ? " on the precise URL " : " on the URL (not precise URL) "} <b className="highlight">{this.state.section}</b>.
                </div>
              ) : null}
              {this.state.triggerType ? (
                <div>
                  This rule is triggered
                  <b className="highlight">{this.state.triggerType === "timer" ? " after " + this.state.seconds + " seconds " : null}</b>
                  <b className="highlight">{this.state.triggerType === "click" ? " on " + this.state.triggerType : null}</b>
                  {this.state.triggerType === "click" ? "  in the element: " : null}
                  <b className="highlight">{this.state.triggerType === "click" ? this.state.selector + " " + this.state.selector_wbo_id : null}</b>
                  <b className="highlight">{this.state.triggerType === "load" ? " on " + this.state.triggerType : null}</b> and will call <b className="highlight">{this.state.url}</b>
                </div>
              ) : null}

              {this.state.dropCookie && this.state.cookieKey && this.state.cookieValue ? (
                <div>
                  The cookie with the key <b className="highlight">{this.state.cookieKey}</b> and value <b>{this.state.cookieValue}</b> will be saved on the user browser for <b className="highlight">{this.state.cookieDuration}</b> days
                </div>
              ) : null}
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group></Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Modal open={this.state.overwriteModal} size="small">
          <Header icon={<Icon name={"exclamation triangle"} color={"orange"} />} content={"Existing rules for WAM account " + this.props.wamID} />
          <Modal.Content>
            <p>We found that rules were already set to this WAM account ID.</p>
            <p>
              If you modify or upload a set of rules to the CDN regarding WAM {this.props.wamID} this will overwrite the existing set.
              <br />
              <b>
                <u>Backup the current set if you are not sure</u>
              </b>{" "}
              or load existing values for WAM account {this.props.wamID} and modify them.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.exportExcel.bind(this)} color="grey">
              <Icon name="download" />
              Excel Backup
            </Button>
            <Button onClick={this.backupCurrentRules.bind(this)} color="grey">
              <Icon name="js" />
              JSON Backup
            </Button>
            <Button onClick={this.closeOverwriteModal.bind(this)} color="orange">
              <Icon name="close" /> Close
            </Button>
            <Button onClick={this.setRuleFromExternalData.bind(this)} color="blue">
              <Icon name="redo" />
              Load existing values
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.tagModal} size="large">
          <Header icon={<Icon name={"code"} color={"grey"} />} content="Script" />
          <Modal.Content>
            <p>Script to implement on the client / advertiser / publisher site. See intructions below.</p>
            <Form>
              <Form.Field as="code" content={this.state.tagExport} readOnly={true} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={!isNil(this.props.wamID) && this.state.saved ? false : true} icon={"download"} content={"Download script and instructions"} onClick={this.getTag.bind(this)} />
            <Button onClick={this.closeTagModal.bind(this)} color="blue">
              <Icon name="close" />
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.showSaveModal} size="small">
          <Header icon={<Icon name={"save"} color={"yellow"} />} content="Save Rule" />
          <Modal.Content>
            <p>You left modification unsaved on rule '{this.state.ruleName}'.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeSaveModal.bind(this)} color="orange">
              <Icon name="close" />
              Close
            </Button>
            <Button onClick={this.saveRules.bind(this)} color="olive">
              <Icon name="save" />
              Save
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.cdnStatusModal} size="small" dimmer={true}>
          <Modal.Content>
            <p style={{ textAlign: "center" }}>
              <b>{this.state.cdnStatus} </b>
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.toggleCDNStatusModal.bind(this)} color="olive">
              <Icon name="check circle" />
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    wamID: state.Reducer.Board.wamID,
    boardLabel: state.Reducer.Board.boardLabel,
    rules: state.Reducer.Board.rules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWAMID: (id) => {
      dispatch(setWAMIDAction(id));
    },
    setLabel: (data) => {
      dispatch(setLabelAction(data));
    },
    saveBoardRules: (rules) => {
      dispatch(saveBoardRulesAction(rules));
    },
    setBoardRules: (rules) => {
      dispatch(setBoardRulesAction(rules));
    },
    deleteBoardRule: (rules) => {
      dispatch(deleteBoardRuleAction(rules));
    },
    addBoardRule: (rules) => {
      dispatch(addBoardRuleAction(rules));
    },
    setIDOfRules: (id) => {
      dispatch(setIDOfRulesAction(id));
    },
    uploadDataRule: (data, label, wamid, callback) => {
      dispatch(
        request(
          UPLOAD_RULE.url,
          null,
          {
            method: UPLOAD_RULE.method,
            bodyData: { documents: { rules: data, label: label, wamid: wamid }, wamid: wamid },
          },
          callback,
        ),
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardTag));
