import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Grid, Button, Icon, Modal, Header, Message, Input, Table, TextArea, Segment, Container, Checkbox } from "semantic-ui-react";
import { set, get, each, map, cloneDeep, find, concat, isNil, unset, sortBy, uniq } from "lodash";
import uuid from "uuid";
import { setDataFeedAction, request, toggleModalAction, toggleSplitToolsAction, setFeedIDAction, setClientNameAction, setFeedUUIDAction } from "./Actions.js";
import { UPLOAD_FEED } from "./Constants.js";
import XLSX from "xlsx";
import moment from "moment";
class FeedCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingFeed: false,
      loadFeed: false,
      errorOnSave: false,
      errorMessage: "",
      keyName: "",
      editInputs: {},
      splitDimension: null,
      currentSelectedKey: null,
      keyNameError: false,
    };
  }

  handleUUIDFeed(e, data) {
    this.props.setFeedUUID(data.value);
    if (data.value.length > 0) {
      this.setState({ loadFeed: true });
    } else {
      this.setState({ loadFeed: false });
    }
  }

  saveToTXT() {
    var data = "Feed List\n\n\n";
    each(this.props.uploadOutputData, (item, index) => {
      if (get(item, "is_master", false) === false) {
        data += get(this.props.data, "splits[" + index + "]", "") + ":\n";
        data += get(item, "cdn", "").replace("cloud.weborama.design", "ams3.digitaloceanspaces.com/weborama") + "\n\n";
      } else {
        data += "All items:\n";
        data += get(item, "cdn", "").replace("cloud.weborama.design", "ams3.digitaloceanspaces.com/weborama") + "\n\n";
      }
    });

    var file = new Blob([data], { type: "text/plain" });
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = this.props.feedName + ".txt";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  exportExcel() {
    var nameFile = " ";
    if (this.props.feedUUID) {
      nameFile = " - " + this.props.feedUUID + " - ";
    } else {
      nameFile = " ";
    }

    var wb = XLSX.utils.book_new();
    var ws = null;
    var ws_data = [];
    //INTRO PAGE
    var ws_name = "Feed " + this.props.feedUUID;
    if (get(this.props.data, "keys", []).length > 0) {
      ws_data.push(get(this.props.data, "keys", []));
    } else {
      ws_data.push(["banner_id", "dimension", "theme", "text_element_frame1", "graphic_element_frame1", "text_element_frame2", "landing_page", "retargeting_key"]);
      ws_data.push(["0", "300x250", "Eco", "Save the Planet", "https://mysite.com/background_image.jpg", "Recicle.", "https://savetheplanet.com", "site_visitor"]);
    }

    each(get(this.props.data, "items", []), (format) => {
      var format_values = [];
      each(format, (value, key) => {
        format_values.push(get(format, key, "?"));
      });
      ws_data.push(format_values);
    });
    ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name.substring(0, 31));
    XLSX.writeFile(wb, "Feed " + nameFile + moment().format("YYYY-MM-DD") + ".xlsx");
  }

  uploadExcel() {
    this.resetTable();
    var _data = cloneDeep(this.props.data);
    var flag = false;
    var reader = new FileReader();
    var KEYS = [];
    var ITEMS = [];

    reader.onload = (e) => {
      console.log(e);
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: "binary",
      });
      var EXCEL_DATA = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
      this.props.setFeedUUID(get(workbook, "SheetNames[0]", "").replace("Feed ", ""));
      var flag_duplicated = false;
      var _duplicated = [];
      var _keys = get(this.props.data, "keys", []);
      each(EXCEL_DATA, (row, index) => {
        each(Object.keys(row), (key, index) => {
          if (KEYS.indexOf(key) < 0) {
            if (_keys.indexOf(key) < 0) {
              KEYS.push(key);
            } else {
              if (_duplicated.indexOf(key) < 0) {
                _duplicated.push(key);
              }
              flag_duplicated = true;
            }
          }
        });
      });
      if (flag_duplicated) {
        alert("Duplicated keys were found. No duplicated keys allowed.\n\n" + "The following keys were not added:\n" + _duplicated);
      }
      KEYS = concat(_keys, KEYS);
      each(EXCEL_DATA, (row, index) => {
        var _item = {};
        each(KEYS, (key, index) => {
          set(_item, key, get(row, key, ""));
        });
        set(_item, "uuid", uuid.v4());
        ITEMS.push(_item);
      });
      set(_data, "keys", KEYS);
      set(_data, "items", ITEMS);
      this.props.setData(_data);
      document.querySelector("#excel-input").value = null;
    };

    reader.onerror = (ex) => {
      console.log(ex);
    };

    reader.readAsBinaryString(document.querySelector("#excel-input").files[0]);
  }

  saveFeed() {
    var UUID = "";
    if (this.props.feedUUID.length > 0) {
      UUID = this.props.feedUUID;
    }
    setTimeout(() => {
      this.props.setFeedUUID("");
    }, 0);

    setTimeout(() => {
      this.setState({ loading: true });
      var SPLITS = [];
      if (get(this.props.data, "splits", []).length > 0) {
        each(get(this.props.data, "splits", []), (split, index) => {
          var array = [];
          each(get(this.props.data, "items", []), (item, index) => {
            if (get(item, get(this.props.data, "split_key", null), null) === split) {
              array.push(item);
            }
          });
          SPLITS.push({
            split: split,
            split_index: get(this.props.data, "splits_indexes[" + index + "]", uuid.v4()),
            split_key: get(this.props.data, "split_key", null),
            data: array,
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
        });
        SPLITS.push({
          split: "master",
          split_key: get(this.props.data, "split_key", null),
          keys: get(this.props.data, "keys", null),
          splits: get(this.props.data, "splits", []),
          data: get(this.props.data, "items", []),
          time: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      } else {
        SPLITS = [
          {
            split: "master",
            split_key: "none",
            keys: get(this.props.data, "keys", null),
            splits: get(this.props.data, "splits", []),
            data: get(this.props.data, "items", []),
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        ];
      }
      this.props.uploadDataFeed(SPLITS, UUID, (response) => {
        this.setState({ loading: false });
        console.log(response);
        if (!isNil(response)) {
          this.props.setFeedUUID(get(find(response, { is_master: true }), "uuid", null));
          this.props.toggleModal(true, response);
          this.setState({
            errorOnSave: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorOnSave: true,
            errorMessage: "Error uploading file. Contact support.",
          });
        }
      });
    }, 100);
  }

  setFeedData(type, e) {
    console.log(type);
    var _data = cloneDeep(this.props.data);
    set(_data, type, e.target.value);
    this.props.setData(_data);
  }

  changeKeyName(e, data) {
    var currentKeys = get(this.props.data, "keys", []);
    if (currentKeys.indexOf(data.value) >= 0) {
      this.setState({ keyName: data.value, keyNameError: true });
    } else {
      this.setState({ keyName: data.value, keyNameError: false });
    }
  }

  createItemKey(e, data) {
    var _data = cloneDeep(this.props.data);
    var _keys = get(_data, "keys", []);
    var _items = get(_data, "items", []);
    _keys.push(this.state.keyName);

    each(_items, (item, index) => {
      each(_keys, (key, index) => {
        set(item, key, get(item, key, ""));
      });
    });

    set(_data, "items", _items);
    set(_data, "keys", _keys);

    this.props.setData(_data);
    this.setState({ keyName: "", keyNameError: false });
  }

  resetTable() {
    this.props.setData({});
    this.props.toggleSplitTools(false);
    this.props.setFeedUUID("");
  }

  currentKeyChange(e, data) {
    this.unsplitKeys();
    this.setState({
      currentSelectedKey: null,
    });
  }
  unsplitKeys() {
    var _data = cloneDeep(this.props.data);
    unset(_data, "splits");
    unset(_data, "split_key");
    unset(_data, "splits_indexes");
    this.setState({
      currentSelectedKey: null,
    });
    this.props.setData(_data);
  }
  splitItemsByKey(e, data) {
    var _data = cloneDeep(this.props.data);
    var _splits = [];
    each(get(_data, "items", []), (item, index) => {
      var splitKey = get(item, this.state.currentSelectedKey, null);
      if (_splits.indexOf(splitKey) < 0) {
        _splits.push(splitKey);
      }
    });
    var _splitsIndexes = [];
    each(_splits, (s, k) => {
      _splitsIndexes.push(uuid.v4());
    });
    set(_data, "splits", _splits);
    set(_data, "split_key", this.state.currentSelectedKey);
    set(_data, "splits_indexes", _splitsIndexes);
    this.setState({ splitDimension: this.state.currentSelectedKey });
    this.props.setData(_data);
  }

  addFeedItem(item) {
    var _data = cloneDeep(this.props.data);
    var _items = get(_data, "items", []);
    _items.push(item);
    this.setState({ keyName: "", keyNameError: false });
    this.props.setData(_data);
  }

  clickKey(e, data) {
    setTimeout(() => {
      this.setState({ currentSelectedKey: data.value });
    }, 100);
    setTimeout(() => {
      this.splitItemsByKey();
    }, 200);
  }

  deleteItemKey() {
    var _data = cloneDeep(this.props.data);
    var _new_keys = [];
    each(get(_data, "keys", []), (k, i) => {
      if (k !== this.state.currentSelectedKey) {
        _new_keys.push(k);
      }
    });

    each(get(_data, "items", []), (item, i) => {
      unset(item, this.state.currentSelectedKey);
    });

    set(_data, "keys", _new_keys);
    this.setState({ currentSelectedKey: null });
    this.props.setData(_data);
    if (this.state.currentSelectedKey === get(this.props.data, "split_key", null)) {
      this.unsplitKeys();
    }
  }

  enableEditMode(row, prop) {
    console.log(row, prop);
    var editInputs = cloneDeep(this.state.editInputs);
    set(editInputs, row + "." + prop, true);
    this.setState({ editInputs: editInputs, editMode: true });
  }

  renderCellElement(value) {
    return <div>{value}</div>;
  }

  closeEditMode() {
    console.log("close edit mode");
    var editInputs = cloneDeep(this.state.editInputs);
    each(editInputs, (row, index) => {
      each(row, (props, key) => {
        console.log(index, key);
        set(editInputs, index + "." + key, false);
      });
    });
    this.setState({ editMode: false, editInputs: editInputs });
  }

  changeValue(row, prop, e, data) {
    console.log(row, prop);
    var _data = cloneDeep(this.props.data);
    var _items = get(_data, "items", []);
    var item = find(_items, { uuid: row });
    set(item, prop, data.value);
    this.props.setData(_data);
  }

  handleEnter(e) {
    if (e.key === "Enter" || e.key === "Esc") {
      var editInputs = cloneDeep(this.state.editInputs);
      each(editInputs, (inputs, index) => {
        each(inputs, (input, key) => {
          console.log(input, index, key);
          set(editInputs, index + "." + key, false);
        });
      });
      this.setState({ editInputs: editInputs });
    }
  }
  deleteRow(uuid) {
    var _data = cloneDeep(this.props.data);
    var _items = [];
    each(get(_data, "items", []), (item, index) => {
      if (item.uuid !== uuid) {
        _items.push(item);
      }
    });
    set(_data, "items", _items);
    this.props.setData(_data);
  }
  loadFeed() {
    this.setState({ loadingFeed: true });
    fetch("https://ams3.digitaloceanspaces.com/weborama/feeds/" + this.props.feedUUID + ".json")
      .then((response) => {
        console.log(response);
        if (response.status >= 400) {
          alert("Feed ID incorrect");
          this.setState({
            loadingFeed: false,
            errorMessage: "",
          });
          this.props.setFeedUUID("");
          return Promise.reject("URL not found");
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          var _data = {};
          set(_data, "items", get(data, "data", []));
          set(_data, "keys", get(data, "keys", []));
          set(_data, "split_key", get(data, "split_key", []));
          set(_data, "splits", get(data, "splits", []));
          this.props.setClientName(get(data, "feed_name", ""));
          this.props.setFeedID(this.props.feedUUID);
          this.props.setData(_data);
          this.setState({
            loadingFeed: false,
            errorOnSave: false,
            currentSelectedKey: get(data, "split_key", []),
            errorMessage: "",
          });
        }
      });
  }
  toggleSplitTools() {
    this.props.toggleSplitTools(!this.props.showSplitTools);
  }
  addRow(split) {
    var _data = cloneDeep(this.props.data);
    var _items = get(_data, "items", []);
    var _new_obj = {};

    each(get(this.props.data, "keys", []), (key, data) => {
      set(_new_obj, key, "");
    });
    if (split) {
      set(_new_obj, get(this.props.data, "split_key", null), split);
    }
    set(_new_obj, "uuid", uuid.v4());
    _items.push(_new_obj);
    set(_data, "items", _items);
    this.props.setData(_data);
  }

  renderTable(DATA_SPLIT, table_key) {
    var currentSplit = null;
    var columns = Object.keys(DATA_SPLIT[0]).length;
    if (get(this.props.data, "splits", []).length > 0) {
      currentSplit = get(this.props.data, "splits", [])[table_key];
    }
    var KEYS = uniq(get(this.props.data, "keys", []));
    KEYS.push("actions");
    KEYS = uniq(KEYS);
    return (
      <Container key={table_key} fluid={true} className="table-container">
        <Table striped selectable onBlur={this.closeEditMode.bind(this)} celled fixed collapsing compact size={"small"} className="FeedTable excel-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={columns} textAlign={"left"}>
                {get(this.props.data, "splits", []).length > 0 ? get(this.props.data, "splits[" + table_key + "]", "") : "All items"}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              {map(KEYS, (key, index) => {
                return <Table.HeaderCell key={index}>{key}</Table.HeaderCell>;
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(DATA_SPLIT, (item, rowindex) => {
              return (
                <Table.Row key={rowindex}>
                  {map(KEYS, (key, index) => {
                    if (key === "actions") {
                      return (
                        <Table.Cell prop={key} key={key}>
                          <Button color={"red"} onClick={this.deleteRow.bind(this, get(item, "uuid", null))} size="mini" icon={"trash"} />
                        </Table.Cell>
                      );
                    } else {
                      return (
                        <Table.Cell collapsing singleLine={false} onClick={this.enableEditMode.bind(this, get(item, "uuid", null), key)} row={rowindex} prop={key} key={key}>
                          {get(this.state.editInputs, get(item, "uuid", null) + "." + key, false) ? (
                            <TextArea autoFocus={true} rows={10} onChange={this.changeValue.bind(this, get(item, "uuid", null), key)} value={get(item, key, "-")} />
                          ) : (
                            <span>{this.renderCellElement(get(item, key, "-"))}</span>
                          )}
                        </Table.Cell>
                      );
                    }
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell textAlign="right" colSpan={columns}>
                {Object.keys(this.props.data).length > 0 ? <Button content={"Add Row"} icon={"plus"} onClick={this.addRow.bind(this, currentSplit)} /> : null}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>
    );
  }

  render() {
    var keysOptions = map(get(this.props.data, "keys", []), (key, index) => {
      return {
        key: index,
        onClick: this.clickKey.bind(this),
        value: key,
        text: key,
      };
    });
    var SPLITS = [];
    if (get(this.props.data, "splits", []).length > 0) {
      each(get(this.props.data, "splits", []), (split, index) => {
        var array = [];
        each(get(this.props.data, "items", []), (item, index) => {
          if (get(item, get(this.props.data, "split_key", null), null) === split) {
            array.push(item);
          }
        });
        SPLITS.push(array);
      });
    }
    if (SPLITS.length < 1) {
      SPLITS = [get(this.props.data, "items", [])];
    }
    console.log(SPLITS);
    return (
      <Grid className="Feeds" padded onKeyPress={this.handleEnter.bind(this)}>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Field width={2} control={Input} fluid={true} label="Feed ID" value={this.props.feedUUID} onChange={this.handleUUIDFeed.bind(this)} placeholder="ID" />

                <Form.Field
                  width={2}
                  label="Load Feed"
                  control={Button}
                  loading={this.state.loadingFeed}
                  color={"orange"}
                  disabled={this.props.feedUUID.length < 3}
                  fluid={true}
                  onClick={this.loadFeed.bind(this)}>
                  <Icon name="cloud download" />
                </Form.Field>
                <Form.Field width={8}></Form.Field>

                <Form.Button
                  content={
                    <label htmlFor="excel-input" className={"upload-field full"}>
                      Excel
                    </label>
                  }
                  className={"button-input-excel nopad-nomargin"}
                  width={2}
                  label={"Import"}
                  fluid={true}
                  color={"facebook"}></Form.Button>
                <Form.Field width={2} control={Button} label={"Download"} color={"olive"} fluid={true} onClick={this.exportExcel.bind(this)}>
                  Excel
                  <Icon name="arrow right" />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        {Object.keys(this.props.data).length > 0 ? (
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} mobile={16}>
              {map(SPLITS, (data_split, key) => {
                return this.renderTable(data_split, key);
              })}
            </Grid.Column>
          </Grid.Row>
        ) : null}

        {this.props.showSplitTools ? (
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} mobile={16}>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    fluid={true}
                    width={4}
                    label={"Item key"}
                    value={this.state.keyName}
                    placeholder="Add a key"
                    error={this.state.keyNameError}
                    onChange={this.changeKeyName.bind(this)}
                  />
                  <Form.Field
                    control={Input}
                    fluid={true}
                    width={4}
                    label="Add Key"
                    content={"Add Key"}
                    disabled={this.state.keyName.length < 1 || this.state.keyNameError}
                    onClick={this.createItemKey.bind(this)}
                  />
                  <Form.Dropdown
                    selection
                    onChange={this.currentKeyChange.bind(this)}
                    label={"Current keys"}
                    width={4}
                    clearable
                    disabled={keysOptions.length < 1}
                    value={this.state.currentSelectedKey}
                    fluid={true}
                    options={keysOptions}
                  />
                  {get(this.props.data, "splits", []).length < 1 ? (
                    <Form.Field
                      label={"Split items"}
                      width={4}
                      control={Button}
                      fluid={true}
                      content={
                        <span>
                          <Icon name="sitemap" />
                          <span>Split</span>
                        </span>
                      }
                      disabled={isNil(this.state.currentSelectedKey)}
                      onClick={this.splitItemsByKey.bind(this)}
                    />
                  ) : (
                    <Form.Field
                      color="facebook"
                      control={Button}
                      fluid={true}
                      width={4}
                      label={"Unify"}
                      content={
                        <span>
                          <Icon name="trash" />
                          Unify
                        </span>
                      }
                      disabled={isNil(get(this.props.data, "split_key", null))}
                      onClick={this.unsplitKeys.bind(this)}
                    />
                  )}
                  <Form.Button
                    color="red"
                    fluid={true}
                    width={4}
                    icon={"trash"}
                    label="Delete key"
                    content={get(this.props.data, "split_key", null)}
                    disabled={isNil(this.state.currentSelectedKey)}
                    onClick={this.deleteItemKey.bind(this)}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        ) : null}

        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Group>
                <Form.Field
                  width={2}
                  control={Button}
                  label={"Split Tools"}
                  fluid={true}
                  icon={this.props.showSplitTools ? "th" : "th list"}
                  color={this.props.showSplitTools ? "blue" : "facebook"}
                  onClick={this.toggleSplitTools.bind(this)}
                />
                <Form.Field control={Button} width={2} label={"Reset Table"} onClick={this.resetTable.bind(this)} fluid={true}>
                  <Icon name="erase" />
                </Form.Field>

                <Form.Field width={10}></Form.Field>
                <Form.Field
                  width={2}
                  loading={this.state.loading}
                  control={Button}
                  label={"Upload Feed"}
                  fluid={true}
                  disabled={Object.keys(this.props.data).length < 1 || this.state.loading}
                  icon={"cloud upload"}
                  color={"blue"}
                  onClick={this.saveFeed.bind(this)}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Modal open={this.state.errorOnSave} size="small">
          <Modal.Content>
            <Message negative={true}>
              <Icon name="exclamation triangle" />
              {this.state.errorMessage}
            </Message>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({ errorOnSave: false });
              }}
              color="red">
              <Icon name="close" /> Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.props.showFeedModal} size="small">
          <Header icon="feed" content="Feed" />
          <Modal.Content>
            <p>Please copy the following ID to access to the feed.</p>
            <Segment padded={false} basic={true}>
              <Input fluid={true} value={this.props.feedUUID} readOnly={true} />
            </Segment>
            <Segment padded={false} basic={true}>
              <label>
                <b>{get(this.props.data, "splits", []).length < 2 ? "" : "Feed URL by split:"}</b>
              </label>
            </Segment>
            {map(this.props.uploadOutputData, (output, index) => {
              if (!output.is_master) {
                return (
                  <Segment key={index} padded={false} basic={true}>
                    <label>{get(this.props.data, "splits[" + index + "]", "")}</label>
                    <Input fluid={true} value={get(output, "cdn", "no url... contact support").replace("cloud.weborama.design", "ams3.digitaloceanspaces.com/weborama")} readOnly={true} />
                  </Segment>
                );
              } else {
                return (
                  <Segment key={index} padded={false} basic={true}>
                    <label>
                      <b>All items:</b>
                    </label>
                    <Input fluid={true} value={get(output, "cdn", "no url... contact support").replace("cloud.weborama.design", "ams3.digitaloceanspaces.com/weborama")} readOnly={true} />
                  </Segment>
                );
              }
            })}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.toggleModal.bind(this, false, null)} color="red">
              <Icon name="close" /> Close
            </Button>
            <Button onClick={this.saveToTXT.bind(this)}>
              <Icon name="download" /> Download TXT
            </Button>
          </Modal.Actions>
        </Modal>
        <input type="file" ref={this.excelInput} encType="multipart/form-data" id="excel-input" className="hidden-inputs" onChange={this.uploadExcel.bind(this)} />
      </Grid>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    data: state.Reducer.Feed.data,
    formats: state.Reducer.App.formats,
    section: state.Reducer.App.section,
    showFeedModal: state.Reducer.Feed.showFeedModal,
    showSplitTools: state.Reducer.Feed.showSplitTools,
    feedUUID: state.Reducer.Feed.feedUUID,
    uploadOutputData: state.Reducer.Feed.uploadOutputData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFeedID: (id) => {
      dispatch(setFeedIDAction(id));
    },
    setData: (data) => {
      dispatch(setDataFeedAction(data));
    },

    setClientName: (name) => {
      dispatch(setClientNameAction(name));
    },
    toggleModal: (show, data) => {
      dispatch(toggleModalAction(show, data));
    },
    setFeedUUID: (uuid) => {
      dispatch(setFeedUUIDAction(uuid));
    },
    toggleSplitTools: (show) => {
      dispatch(toggleSplitToolsAction(show));
    },

    uploadDataFeed: (data, uuid, callback) => {
      dispatch(
        request(
          UPLOAD_FEED.url,
          null,
          {
            method: UPLOAD_FEED.method,
            bodyData: { documents: data, masterIndex: uuid },
          },
          callback,
        ),
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedCreator));
