import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Grid, Button, Icon, Modal, Segment, Header, Message, Input, Dropdown, Container, Checkbox, Label } from "semantic-ui-react";
import { set, get, each, map, cloneDeep, find, isString, isNumber, isBoolean, isNull, isArray, isPlainObject, isEmpty, isNil, isObject, first, has, merge, mergeWith, assign } from "lodash";
import { setRichMediaDataAction, request, toggleRichMediaModalAction, setRichMediaFormatAction, setRichMediaUUIDAction } from "./Actions.js";
import { UPLOAD_RICHMEDIA, RICHMEDIA_FORMATS } from "./Constants.js";
import moment from "moment";
var hrrs = require("human-readable-random-string");
class RichMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingFormat: false,
      loadFormat: false,
      errorOnSave: false,
      errorMessage: "",
      keyNameError: false,
    };
  }

  handleUUIDFormat(e, data) {
    this.props.setRichMediaUUID(data.value);
    if (data.value.length > 0) {
      this.setState({ loadFormat: true });
    } else {
      this.setState({ loadFormat: false });
    }
  }

  componentDidMount() {
    window.onresize = () => {
      this.setState({ windowWidth: window.innerWidth });
    };
  }
  componentWillUnmount() {
    window.onresize = null;
  }

  changeRichMediaFormat(e, data) {
    this.props.setRichMediaFormat(data.value);
    this.props.setRichMediaUUID("");
    this.props.setRichMediaData(get(find(RICHMEDIA_FORMATS, { value: data.value }), "setup", {}));
  }

  saveFormat() {
    var UUID = "";
    console.log(this.props.richMediaUUID);
    console.log(this.props.richMediaUUID.length);
    if (isNil(this.props.richMediaUUID) || this.props.richMediaUUID.length < 1) {
      console.log("1");
      UUID = hrrs(20);
    } else {
      UUID = this.props.richMediaUUID;
      console.log("2");
    }
    setTimeout(() => {
      this.setState({ loading: true });
      this.props.uploadDataRichMedia(
        [
          {
            data: this.props.data,
            format_name: get(find(RICHMEDIA_FORMATS, { value: this.props.richMediaFormat }), "value", "no name"),
            time: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        ],
        UUID,
        (response) => {
          this.setState({ loading: false });
          console.log(response);
          if (!isNil(response)) {
            this.props.setRichMediaUUID(get(response, "uuid", null));
            this.props.toggleRichMediaModal(true, response);
            this.setState({
              errorOnSave: false,
              errorMessage: "",
            });
          } else {
            this.setState({
              errorOnSave: true,
              errorMessage: "Error uploading file. Contact support.",
            });
          }
        },
      );
    }, 100);
  }

  setFormatData(type, e) {
    var _data = cloneDeep(this.props.data);
    set(_data, type, e.target.value);
    this.props.setRichMediaData(_data);
  }

  handleEnter() {}

  loadFormat() {
    this.setState({ loadingFormat: true });
    fetch("https://ams3.digitaloceanspaces.com/weborama/feeds/" + this.props.richMediaUUID + ".json")
      .then((response) => {
        if (response.status >= 400) {
          alert("Rich Media ID incorrect");
          this.props.setRichMediaUUID("");
          this.setState({
            loadingFormat: false,
            errorOnSave: false,
            errorMessage: "",
          });
          return Promise.reject("URL not found");
        } else {
          return response;
        }
      })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.props.setRichMediaUUID(this.props.richMediaUUID);
          this.props.setRichMediaData(get(data, "data", {}));
          this.props.setRichMediaFormat(get(data, "format_name", ""));
          this.setState({
            loadingFormat: false,
            errorOnSave: false,
            errorMessage: "",
          });
        }
      });
  }

  flattenObject(o, prefix = "", result = {}, keepNull = true) {
    if (isString(o) || isNumber(o) || isBoolean(o) || (keepNull && isNull(o))) {
      result[prefix] = o;
      return result;
    }

    if (isArray(o) || isPlainObject(o)) {
      for (let i in o) {
        let pref = prefix;
        if (isArray(o)) {
          pref = pref + `[${i}]`;
        } else {
          if (isEmpty(prefix)) {
            pref = i;
          } else {
            pref = prefix + "." + i;
          }
        }
        this.flattenObject(o[i], pref, result, keepNull);
      }
      return result;
    }
    return result;
  }

  sortLabels(label) {
    label = label.replace(/[.]/g, " ");
    label = label.replace(/[_]/g, " ");
    label = label.replace(/([A-Z])/g, " $1");
    var templabel = label.split(" ");
    var array = [];
    each(templabel, (string) => {
      array.push(string.charAt(0).toUpperCase() + string.slice(1));
    });
    label = array.join(" ");
    return label;
  }
  setupGrid() {
    var obj = {};
    each(this.flattenObject(this.props.data), (value, key) => {
      var k = key.split(".")[0];
      var o = set({}, key.split(".")[1], value);
      if (Object.keys(obj).indexOf(k) >= 0) {
        obj[k].push(o);
      } else {
        set(obj, k, []);
        obj[k].push(o);
      }
    });
    var index = 0;
    return map(obj, (mainvalue, mainkey) => {
      index++;
      return (
        <Grid.Row key={index}>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form>
              <Form.Field control={Header} content={this.sortLabels(mainkey)} />
              <Form.Group widths={this.state.windowWidth < 768 ? "equal" : "16"} inline={false} grouped={Object.keys(mainvalue).length > 4 ? true : false}>
                {map(mainvalue, (key, index) => {
                  var v = key[Object.keys(key)[0]];
                  var k = Object.keys(key)[0];
                  var path = mainkey + "." + k;

                  if (isBoolean(v)) {
                    return (
                      <Form.Field
                        className={"align-vertical-center"}
                        fitted
                        control={Checkbox}
                        key={k}
                        toggle
                        checked={get(this.props.data, path, false)}
                        label={this.sortLabels(k)}
                        onChange={this.handleChange.bind(this, path)}
                      />
                    );
                  } else {
                    return <Form.Field key={k} control={Input} value={get(this.props.data, path, "")} label={this.sortLabels(k)} onChange={this.handleChange.bind(this, path)} />;
                  }
                })}
              </Form.Group>
            </Form>
          </Grid.Column>{" "}
        </Grid.Row>
      );
    });
  }

  handleChange(key, event, dataFromField) {
    var _data = cloneDeep(this.props.data);
    if (has(dataFromField, "checked")) {
      set(_data, key, get(dataFromField, "checked"));
    } else {
      set(_data, key, get(dataFromField, "value"));
    }
    this.props.setRichMediaData(_data);
  }

  render() {
    return (
      <Grid className="RichMedia" padded>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} mobile={16}>
            <Form widths={"equal"}>
              <Form.Group>
                <Form.Field
                  width={4}
                  control={Dropdown}
                  fluid={true}
                  label="Rich Media Formats"
                  options={RICHMEDIA_FORMATS}
                  selection
                  clearable
                  value={this.props.richMediaFormat}
                  onChange={this.changeRichMediaFormat.bind(this)}
                  placeholder="Select a format"
                />
                <Form.Field control={Input} width={8} fluid={true} label="Setup ID" value={this.props.richMediaUUID} onChange={this.handleUUIDFormat.bind(this)} placeholder="ID" />
                <Form.Field
                  width={4}
                  label="Load Setup ID"
                  control={Button}
                  loading={this.state.loadingFormat}
                  color={"orange"}
                  disabled={!this.state.loadFormat}
                  fluid={true}
                  onClick={this.loadFormat.bind(this)}>
                  <Icon name="cloud download" />
                  Load Setup
                </Form.Field>
                <Form.Field
                  width={4}
                  loading={this.state.loading}
                  control={Button}
                  label={"Upload Setup"}
                  fluid={true}
                  disabled={Object.keys(this.props.data).length < 1 || this.state.loading}
                  icon={"cloud upload"}
                  color={"blue"}
                  onClick={this.saveFormat.bind(this)}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Container fluid className="table-container-richmedia">
              {this.setupGrid()}{" "}
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Modal open={this.state.errorOnSave} size="small">
          <Modal.Content>
            <Message negative={true}>
              <Icon name="exclamation triangle" />
              {this.state.errorMessage}
            </Message>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({ errorOnSave: false });
              }}
              color="red">
              <Icon name="close" /> Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.props.showFormatModal} size="small">
          <Header icon="star" content="Rich Media Setup" />
          <Modal.Content>
            <Segment padded={false} basic={true}>
              <label>Copy the ID and paste it into the parameter 'vars' of Weborama tag</label>
            </Segment>
            <Segment padded={false} basic={true}>
              <Input fluid={true} value={this.props.richMediaUUID} readOnly={true} />
            </Segment>
            <Segment padded={false} basic={true}>
              <label>Example:</label>
              <br />
              <code>
                ,clicktrackers : [] <br />
                <b>,vars : ['setup={this.props.richMediaUUID}']</b>
                <br />
                ,publisherclick : ...'
              </code>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.toggleRichMediaModal.bind(this, false, null)} color="red">
              <Icon name="close" /> Close
            </Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    data: state.Reducer.RichMedia.data,
    section: state.Reducer.App.section,
    showFormatModal: state.Reducer.RichMedia.showFormatModal,
    richMediaUUID: state.Reducer.RichMedia.richMediaUUID,
    richMediaFormat: state.Reducer.RichMedia.richMediaFormat,
    uploadOutputData: state.Reducer.RichMedia.uploadOutputData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRichMediaData: (data) => {
      dispatch(setRichMediaDataAction(data));
    },

    toggleRichMediaModal: (show, data) => {
      dispatch(toggleRichMediaModalAction(show, data));
    },
    setRichMediaFormat: (format) => {
      dispatch(setRichMediaFormatAction(format));
    },
    setRichMediaUUID: (uuid) => {
      dispatch(setRichMediaUUIDAction(uuid));
    },

    uploadDataRichMedia: (data, uuid, callback) => {
      dispatch(
        request(
          UPLOAD_RICHMEDIA.url,
          null,
          {
            method: UPLOAD_RICHMEDIA.method,
            bodyData: { documents: data, uuid: uuid },
          },
          callback,
        ),
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RichMedia));
